import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import trackerReducer from '../stores/tracker';
import addressReducer from '../stores/address';
import merchantReducer from '../stores/merchant';
import shipmentReducer from '../stores/shipment';
import alertReducer from '../stores/alert'

export const store = configureStore({
	reducer: {
		tracker: trackerReducer,
		address: addressReducer,
		merchant: merchantReducer,
		shipment: shipmentReducer,
		alert: alertReducer
	}
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
