import {
	Alert,
	Backdrop,
	Button,
	CircularProgress,
	FormControl,
	Grid,
	MenuItem,
	Snackbar,
	TextField,
	Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import AddressAutoComplete from '../../components/Merchant/Shipment/CreateShipmentForm/AddressAutoComplete';
import { axiosConfig } from '../../constants/axios';
import {
	BUTTON,
	createElementId,
	DROPDOWN,
	FORM_CONTROL,
	HEADING,
	MENU_ITEM,
	MERCHANT_PROFILE,
	SELECT,
	SEND_FROM,
	TEXTFIELD
} from '../../constants/id';
import { SnackAlert } from '../../types/util';
import { useMerchant } from '../../context/MerchantContext';

export default function Profile(props: any) {
	const { merchantProfile, setMerchantProfile } = useMerchant();
	const location = useLocation();
	const snack = location.state ? (location.state as { type: string; message: string }) : null;

	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const [merchant_info, setMerchantInfo] = useState(merchantProfile);
	const [openSnackBar, setOpenSnackBar] = useState(false);
	const [apiResponse, setApiResponse] = useState<SnackAlert>({ type: 'success', message: '' });
	const [hasExportersCode, setHasExportersCode] = useState(true);
	const [switchedProfile, setSwitchedProfile] = useState(merchant_info.partitionKey);

	const [merchant_address, setMerchantAddressDetails] = useState({
		merchant_street_1: '',
		merchant_zip_postal: '',
		merchant_city_locality: '',
		merchant_state_province: '',
		merchant_country: ''
	});
	const axiosInstance = axiosConfig();

	useEffect(() => {
		// setCheckbox({
		// 	drop_off: merchant_info.merchant_dropoff,
		// 	collect: merchant_info.merchant_collect
		// });
		if (snack) {
			setApiResponse({ ...(snack as SnackAlert) });
			setOpenSnackBar(true);
		}
	}, []);

	// update form if merchant was changed
	useEffect(() => {
		setValue('merchant_id', merchantProfile.merchant_id);
		setValue('partitionKey', merchantProfile.partitionKey);
		setMerchantInfo(merchantProfile);
		setSwitchedProfile(merchantProfile.partitionKey);
		remove();
	}, [merchantProfile]);

	//const merchantSettings: any = JSON.parse(sessionStorage.getItem('merchant_settings') as string);

	//merchant_info.merchant_settings = merchantSettings;

	const {
		register,
		watch,
		setValue,
		getValues,
		trigger,
		control,
		formState: { errors },
		handleSubmit
	} = useForm({
		mode: 'all',
		defaultValues: {
			...merchant_info
		}
	});

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'tax_identifiers'
	});

	const taxIdentifiers = merchant_info.tax_identifiers || [
		{ tax_id: '', tax_id_type: 'NoType', issuing_country: '' }
	];
	const tax_id = fields.map((field: any, index: number) => taxIdentifiers[index]?.tax_id);
	const tax_id_type = fields.map((field: any, index: number) => taxIdentifiers[index]?.tax_id_type);
	const issuing_country = fields.map((field: any, index: number) => taxIdentifiers[index]?.issuing_country);
	const [merchantDDP, setMerchantDDP] = useState(
		merchant_info.merchant_settings?.ddp === undefined ? false : merchant_info.merchant_settings.ddp
	);
	const [merchantNonDeliveryOption, setMerchantNonDeliveryOption] = useState(
		merchant_info.merchant_settings?.to_return === undefined ? true : merchant_info.merchant_settings.to_return
	);
	const [merchantDeliveryConfirmationOption, setDeliveryConfirmationOption] = useState(
		merchant_info.merchant_settings?.delivery_confirmation === undefined
			? false
			: merchant_info.merchant_settings.delivery_confirmation
	);
	const [useTaxNumber, setUseTaxNumber] = useState(
		merchant_info.merchant_settings?.use_own_tax_id === undefined
			? false
			: merchant_info.merchant_settings.use_own_tax_id
	);
	const [merchantStoreDomain, setMerchantStoreDomain] = useState(merchant_info.store_domain || '');
	const [taxID, setTaxID] = useState(tax_id || ['']);
	const [taxIDType, setTaxIDType] = useState(tax_id_type || ['NoType']);
	const [issuingCountry, setIssuingCountry] = useState(issuing_country || ['']);

	// useEffect(() => {
	// 	console.log('State Update: ', JSON.stringify(watch(), null, 4));
	// }, [watch()]);

	const handleFormSubmit = (event: any) => {
		// event.merchant_dropoff = checked.drop_off;
		// event.merhcant_collect = checked.collect;
		setLoading(true);
		axiosInstance
			.post('/merchant/createMerchant', [{ ...getValues() }])
			.then(response => {
				// dispatch(setMerchant(response.data.merchantRecords[0]));
				sessionStorage.setItem('merchant', JSON.stringify(response.data.merchantRecords[0]));
				//sessionStorage.setItem('merchant_settings', JSON.stringify(response.data.merchantRecords[0].merchant_settings));
				setApiResponse({
					type: 'success',
					message: 'Successfully Updated Profile'
				});

				setOpenSnackBar(true);
				setLoading(false);
				// update context when profile is updated
				setMerchantProfile(response.data.merchantRecords[0]);
				navigate('/', {
					state: { type: 'success', message: 'Successfully Updated Profile', merchant: merchant_info }
				});
			})
			.catch(err => {
				setApiResponse({
					type: 'error',
					message: 'Error: Failed to update profile'
				});
				setOpenSnackBar(true);
				setLoading(false);
				console.log(err);
			}); // eslint-disable-line react-hooks/exhaustive-deps
	};

	// const handleCheckBoxChange = (e: any) => {
	// 	setCheckbox({ collect: !checked.collect, drop_off: !checked.drop_off });
	// 	setMerchantInfo((prevState: any) => ({
	// 		...prevState,
	// 		merchant_dropoff: checked.drop_off,
	// 		merchant_collect: checked.collect
	// 	}));
	// };

	useEffect(() => {
		if (merchant_address.merchant_street_1 || merchant_address.merchant_country) {
			setValue('merchant_street_1', merchant_address.merchant_street_1);
			setValue('merchant_zip_postal', merchant_address.merchant_zip_postal);
			setValue('merchant_city_locality', merchant_address.merchant_city_locality);
			setValue('merchant_state_province', merchant_address.merchant_state_province);
			setValue('merchant_country', merchant_address.merchant_country);
			trigger('merchant_street_1');
			trigger('merchant_zip_postal');
			trigger('merchant_city_locality');
			trigger('merchant_state_province');
			trigger('merchant_country');
		}
	}, [merchant_address]);

	useEffect(() => {
		setMerchantDDP(merchantProfile.merchant_settings?.ddp);
		setDeliveryConfirmationOption(merchant_info.merchant_settings?.delivery_confirmation);
		setMerchantNonDeliveryOption(merchant_info.merchant_settings?.to_return);
		setUseTaxNumber(merchant_info.merchant_settings?.use_own_tax_id);
		setValue('merchant_exporters_code', merchantProfile.merchant_exporters_code);
		setValue('merchant_personal_id', merchantProfile.merchant_personal_id);
		setValue('merchant_name', merchantProfile.merchant_name);
		setValue('merchant_email', merchantProfile.merchant_email);
		setValue('merchant_phone', merchantProfile.merchant_phone);
		setValue('merchant_vat_number', merchantProfile.merchant_vat_number);
		setValue('merchant_street_1', merchantProfile.merchant_street_1);
		setValue('merchant_street_2', merchantProfile.merchant_street_2);
		setValue('merchant_city_locality', merchantProfile.merchant_city_locality);
		setValue('merchant_state_province', merchantProfile.merchant_state_province);
		setValue('merchant_zip_postal', merchantProfile.merchant_zip_postal);
		setValue('merchant_country', merchantProfile.merchant_country);
		setValue('merchant_settings.ddp', merchantProfile.merchant_settings?.ddp);
		setValue('merchant_settings.to_return', merchant_info.merchant_settings?.to_return);
		setValue('merchant_settings.delivery_confirmation', merchant_info.merchant_settings?.delivery_confirmation);
		setValue('merchant_settings.use_own_tax_id', merchant_info.merchant_settings?.use_own_tax_id);
		setValue('store_domain', merchantProfile.store_domain);

		trigger([
			'merchant_name',
			'merchant_email',
			'merchant_phone',
			'merchant_vat_number',
			'merchant_exporters_code',
			'merchant_personal_id',
			'merchant_street_1',
			'merchant_street_2',
			'merchant_city_locality',
			'merchant_state_province',
			'merchant_zip_postal',
			'merchant_country',
			'merchant_settings.ddp',
			'merchant_settings.to_return',
			'merchant_settings.delivery_confirmation',
			'merchant_settings.use_own_tax_id',
			'store_domain'
		]);

		if (fields.length < taxIdentifiers.length && tax_id !== null && tax_id_type !== null) {
			setUseTaxNumber(
				merchant_info.merchant_settings?.use_own_tax_id === undefined
					? false
					: merchant_info.merchant_settings.use_own_tax_id
			);
			taxIdentifiers.map((tax: any) =>
				append({
					tax_id: tax.tax_id || '',
					tax_id_type: tax.tax_id_type || 'NoType',
					issuing_country: tax.issuing_country || ''
				})
			);
		}
	}, [switchedProfile]);

	const handleAlertClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpenSnackBar(false);
	};

	const handleAddressChange = (e: any) => {
		const { name, value } = e.target;
		setValue(name, value);
		trigger(name);
	};

	const handleDutiesChange = (event?: any) => {
		if (event.target.value === 'true') {
			setMerchantDDP(true);
			setValue('merchant_settings.ddp', true);
		} else {
			setMerchantDDP(false);
			setValue('merchant_settings.ddp', false);
		}
	};

	const handleNonDeliveryOptionChange = (e: any) => {
		if (e.target.value === 'true') {
			setValue('merchant_settings.to_return', true);
			setMerchantNonDeliveryOption(true);
		} else {
			setValue('merchant_settings.to_return', false);
			setMerchantNonDeliveryOption(false);
		}
	};

	const handleDeliveryConfirmationChange = (e: any) => {
		if (e.target.value === 'true') {
			setValue('merchant_settings.delivery_confirmation', true);
			setDeliveryConfirmationOption(true);
		} else {
			setValue('merchant_settings.delivery_confirmation', false);
			setDeliveryConfirmationOption(false);
		}
	};

	const handleStoreDomainChange = (e: any) => {
		setMerchantStoreDomain(e.target.value);
		setValue('store_domain', e.target.value);
	};

	const handleUseTaxNumber = (e: any, index: number) => {
		if (e.target.value === 'YES') {
			setUseTaxNumber(true);
			setValue('merchant_settings.use_own_tax_id', true);
		} else {
			setUseTaxNumber(false);
			setValue('merchant_settings.use_own_tax_id', false);
		}
	};

	const handleTaxidTypeChange = (e: any, index: number) => {
		if (e.target.value === 'VAT') {
			setTaxIDType(e.target.value);
			setValue(`tax_identifiers.${index}.tax_id_type`, e.target.value);
		} else if (e.target.value === 'EORI') {
			setTaxIDType(e.target.value);
			setValue(`tax_identifiers.${index}.tax_id_type`, e.target.value);
		} else if (e.target.value === 'IOSS') {
			setTaxIDType(e.target.value);
			setValue(`tax_identifiers.${index}.tax_id_type`, e.target.value);
		} else {
			setTaxIDType(['NoType']);
			setValue(`tax_identifiers.${index}.tax_id_type`, 'NoType');
		}
	};

	const handleTaxIDChange = (e: any, index: number) => {
		handleTaxIDTypeLength(e, index);
	};

	const handleTaxIDTypeLength = (e: any, index: number): boolean => {
		const taxID = e.target ? e.target.value : e;

		const isValidVAT = (taxID: string): boolean => {
			// VAT should be 8-12 characters long
			return taxID.length >= 8 && taxID.length <= 12;
		};

		const isValidEORI = (taxID: string): boolean => {
			// EORI should be 12-15 characters long and start with a country code
			const eoriRegex = /^[A-Z]{2}[0-9]{10,13}$/;
			return eoriRegex.test(taxID);
		};

		const isValidIOSS = (taxID: string): boolean => {
			// IOSS should be 12-14 characters long and start with 'IM'
			const iossRegex = /^IM[0-9A-Z]{10,12}$/;
			return iossRegex.test(taxID);
		};

		const isValidTIN = (taxID: string): boolean => {
			// TIN should be 9-11 characters long
			return taxID.length >= 9 && taxID.length <= 11;
		};

		const isValidVOEC = (taxID: string): boolean => {
			// VOEC should be 13 characters long and start with 'VO'
			const voecRegex = /^VO[0-9A-Z]{11}$/;
			return voecRegex.test(taxID);
		};

		const isValidHMRC = (taxID: string): boolean => {
			// HMRC should be 12-15 characters long and start with 'GB'
			const hmrcRegex = /^GB[0-9A-Z]{10,13}$/;
			return hmrcRegex.test(taxID);
		};

		let isValid = false;
		const taxType = getValues(`tax_identifiers.${index}.tax_id_type`);

		if (taxType === 'VAT') {
			isValid = isValidVAT(taxID);
		} else if (taxType === 'EORI') {
			isValid = isValidEORI(taxID);
		} else if (taxType === 'IOSS') {
			isValid = isValidIOSS(taxID);
		} else if (taxType === 'TIN') {
			isValid = isValidTIN(taxID);
		} else if (taxType === 'VOEC') {
			isValid = isValidVOEC(taxID);
		} else if (taxType === 'HMRC') {
			isValid = isValidHMRC(taxID);
		}

		setTaxID(taxID);
		setValue(`tax_identifiers.${index}.tax_id`, taxID);

		return isValid;
	};

	const handleIssuingCountryChange = (e: any, index: number) => {
		setIssuingCountry(e.target.value);
		setValue(`tax_identifiers.${index}.issuing_country`, e.target.value);
	};

	return (
		<Grid container direction="row" alignContent="center" justifyContent="center">
			<Grid item xs={12} sm={12} md={12} lg={10} xl={10}>
				<Grid container direction="row" alignContent="center" justifyContent="center" py={2} my={2}>
					<Grid item xs={10} sm={10} md={5} lg={4} xl={4}>
						<Typography
							id={createElementId([MERCHANT_PROFILE, FORM_CONTROL, HEADING, 'merchant_profile'])}
							variant="h3"
						>
							Merchant Profile
						</Typography>
					</Grid>
				</Grid>
				<form onSubmit={handleSubmit(handleFormSubmit)}>
					<Grid container direction="row" alignContent="center" justifyContent="center" lineHeight={5} py={2}>
						<Grid container spacing={2} justifyContent="center" alignContent="center">
							<Grid item xs={10} sm={10} md={5} lg={4} xl={4}>
								<TextField
									id={createElementId([MERCHANT_PROFILE, FORM_CONTROL, TEXTFIELD, 'merchant_name'])}
									required
									label="Company Name"
									fullWidth
									{...register('merchant_name', { required: 'Required' })}
									error={!!errors?.merchant_name}
									helperText={errors?.merchant_name ? (errors.merchant_name.message as string) : null}
								/>
							</Grid>
							<Grid item xs={10} sm={10} md={5} lg={4} xl={4}>
								<TextField
									id={createElementId([MERCHANT_PROFILE, FORM_CONTROL, TEXTFIELD, 'merchant_email'])}
									required
									label="Email"
									{...register('merchant_email', {
										required: 'Required',
										pattern: {
											value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
											message: 'Invalid email address'
										}
									})}
									error={!!errors?.merchant_email}
									helperText={
										errors?.merchant_email ? (errors.merchant_email.message as string) : null
									}
									fullWidth
								/>
							</Grid>
						</Grid>
						{/* <Grid container spacing={2} justifyContent="center" alignContent="center">
								<Grid item xs={10} sm={10} md={5} lg={4} xl={4}>
									<TextField
										required
										label="User First Name"
										{...register('merchant_user_first_name', { required: 'Required' })}
										helperText={
											errors?.merchant_user_first_name
												? (errors.merchant_user_first_name.message as string)
												: null
										}
										fullWidth
									/>
								</Grid>
								<Grid item xs={10} sm={10} md={5} lg={4} xl={4}>
									<TextField
										required
										label="User Last Name"
										{...register('merchant_user_last_name', { required: 'Required' })}
										helperText={
											errors?.merchant_user_last_name
												? (errors.merchant_user_last_name.message as string)
												: null
										}
										fullWidth
									/>
								</Grid>
							</Grid> */}
						<Grid container spacing={2} justifyContent="center" alignContent="center">
							<Grid item xs={10} sm={10} md={5} lg={4} xl={4}>
								<TextField
									id={createElementId([MERCHANT_PROFILE, FORM_CONTROL, TEXTFIELD, 'merchant_phone'])}
									required
									label="Phone"
									{...register('merchant_phone', {
										required: 'Required',
										pattern: {
											value: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,8}$/im,
											message: 'Invalid Phone number'
										}
									})}
									error={!!errors?.merchant_phone}
									helperText={
										errors?.merchant_phone ? (errors.merchant_phone.message as string) : null
									}
									fullWidth
								/>
							</Grid>
							<Grid item xs={10} sm={10} md={5} lg={4} xl={4}>
								<TextField
									id={createElementId([
										MERCHANT_PROFILE,
										FORM_CONTROL,
										TEXTFIELD,
										'merchant_vat_number'
									])}
									label="Vat Number"
									fullWidth
									value={watch('merchant_vat_number') || ''}
									{...register('merchant_vat_number', {})}
									error={!!errors?.merchant_vat_number}
									helperText={
										errors?.merchant_vat_number
											? (errors.merchant_vat_number.message as string)
											: null
									}
								/>
							</Grid>
						</Grid>

						<Grid container spacing={2} justifyContent="center" alignContent="center">
							<Grid item xs={10} sm={10} md={3.33} lg={2.66} xl={2.66}>
								<TextField
									id={createElementId([
										MERCHANT_PROFILE,
										FORM_CONTROL,
										TEXTFIELD,
										'merchant_exporters_code'
									])}
									label="Exporter's Code"
									{...register('merchant_exporters_code', {
										pattern: {
											value: /^[a-zA-Z0-9]{8,10}$/im,
											message: 'Invalid Exporters Code'
										}
									})}
									value={watch('merchant_exporters_code') || ''}
									onChange={(e: any) => {
										e.target.value !== '' ? setHasExportersCode(true) : setHasExportersCode(false);
										setValue('merchant_exporters_code', e.target.value);
									}}
									error={!!errors?.merchant_exporters_code}
									helperText={
										errors?.merchant_exporters_code
											? (errors.merchant_exporters_code.message as string)
											: null
									}
									fullWidth
								/>
							</Grid>
							<Grid item xs={10} sm={10} md={3.33} lg={2.66} xl={2.66}>
								<TextField
									id={createElementId([MERCHANT_PROFILE, FORM_CONTROL, TEXTFIELD, 'store_domain'])}
									label="Store Domain"
									{...register('store_domain', {})}
									value={watch('store_domain') || ''}
									onChange={handleStoreDomainChange}
									error={!!errors?.store_domain}
									helperText={errors?.store_domain ? (errors.store_domain.message as string) : null}
									fullWidth
								/>
							</Grid>
							<Grid item xs={10} sm={10} md={3.33} lg={2.66} xl={2.66}>
								<TextField
									id={createElementId([
										MERCHANT_PROFILE,
										FORM_CONTROL,
										TEXTFIELD,
										'merchant_personal_id'
									])}
									required={!hasExportersCode}
									label="ID/Passport Number"
									{...register('merchant_personal_id', {
										pattern: {
											value: /^[0-9]{13}$/im,
											message: 'Invalid Id Number'
										}
									})}
									value={watch('merchant_personal_id') || ''}
									onChange={(e: any) => {
										setValue('merchant_personal_id', e.target.value);
									}}
									error={!!errors?.merchant_personal_id}
									helperText={
										errors?.merchant_personal_id
											? (errors.merchant_personal_id.message as string)
											: null
									}
									fullWidth
								/>
							</Grid>
						</Grid>

						<Grid container spacing={2} justifyContent="center" alignContent="center">
							<Grid item xs={10} sm={10} md={3.33} lg={2.66} xl={2.66}>
								<FormControl variant={'standard'} fullWidth>
									<TextField
										id={createElementId([
											MERCHANT_PROFILE,
											FORM_CONTROL,
											TEXTFIELD,
											DROPDOWN,
											SELECT,
											'delivery_confirmation'
										])}
										select
										value={merchantDeliveryConfirmationOption}
										onChange={handleDeliveryConfirmationChange}
										label="Signature Required"
										fullWidth
									>
										<MenuItem
											id={createElementId([
												MERCHANT_PROFILE,
												FORM_CONTROL,
												TEXTFIELD,
												DROPDOWN,
												MENU_ITEM,
												'adult_signature'
											])}
											value={'true'}
										>
											Adult Signature
										</MenuItem>
										<MenuItem
											id={createElementId([
												MERCHANT_PROFILE,
												FORM_CONTROL,
												TEXTFIELD,
												DROPDOWN,
												MENU_ITEM,
												'no_signature'
											])}
											value={'false'}
										>
											No Signature
										</MenuItem>
									</TextField>
								</FormControl>
							</Grid>
							<Grid item xs={10} sm={10} md={3.33} lg={2.66} xl={2.66}>
								<FormControl variant={'standard'} fullWidth>
									<TextField
										id={createElementId([
											MERCHANT_PROFILE,
											FORM_CONTROL,
											TEXTFIELD,
											DROPDOWN,
											SELECT,
											'non_delivery_option'
										])}
										select
										value={merchantNonDeliveryOption}
										onChange={handleNonDeliveryOptionChange}
										label="Shipment Return Option"
										fullWidth
									>
										<MenuItem
											id={createElementId([
												MERCHANT_PROFILE,
												FORM_CONTROL,
												TEXTFIELD,
												DROPDOWN,
												MENU_ITEM,
												'return'
											])}
											value={'true'}
										>
											Return
										</MenuItem>
										<MenuItem
											id={createElementId([
												MERCHANT_PROFILE,
												FORM_CONTROL,
												TEXTFIELD,
												DROPDOWN,
												MENU_ITEM,
												'abandon'
											])}
											value={'false'}
										>
											Abandon
										</MenuItem>
									</TextField>
								</FormControl>
							</Grid>
							<Grid item xs={10} sm={10} md={3.33} lg={2.66} xl={2.66}>
								<FormControl variant={'standard'} fullWidth>
									<TextField
										id={createElementId([
											MERCHANT_PROFILE,
											FORM_CONTROL,
											TEXTFIELD,
											DROPDOWN,
											SELECT,
											'billing_option'
										])}
										select
										value={merchantDDP}
										onChange={handleDutiesChange}
										label="Default Billing for Duties, Fees and Taxes"
										fullWidth
									>
										<MenuItem
											id={createElementId([
												MERCHANT_PROFILE,
												FORM_CONTROL,
												TEXTFIELD,
												DROPDOWN,
												MENU_ITEM,
												'ddp'
											])}
											value={'true'}
										>
											Bill my account (DDP)
										</MenuItem>
										<MenuItem
											id={createElementId([
												MERCHANT_PROFILE,
												FORM_CONTROL,
												TEXTFIELD,
												DROPDOWN,
												MENU_ITEM,
												'dap'
											])}
											value={'false'}
										>
											Bill the receiver (DAP)
										</MenuItem>
									</TextField>
								</FormControl>
							</Grid>
						</Grid>
						{fields.map((field, index) => (
							<Grid container spacing={2} justifyContent="center" alignContent="center">
								<Grid item xs={10} sm={10} md={1.5} lg={1.5} xl={1.5}>
									{index > 0 ? (
										<Button
											id={createElementId([
												MERCHANT_PROFILE,
												FORM_CONTROL,
												BUTTON,
												index.toString(),
												'remove_item'
											])}
											fullWidth
											disabled={!useTaxNumber}
											variant={'outlined'}
											onClick={() => remove(index)}
											style={{ marginBottom: '30px', height: '75%' }}
										>
											Remove Item
										</Button>
									) : (
										<FormControl variant={'standard'} fullWidth>
											<TextField
												id={createElementId([
													MERCHANT_PROFILE,
													FORM_CONTROL,
													TEXTFIELD,
													DROPDOWN,
													SELECT,
													'use_tax_id'
												])}
												select
												defaultValue={!useTaxNumber ? 'NO' : 'YES'}
												onChange={e => handleUseTaxNumber(e, index)}
												label="Use Tax ID?"
												error={!!errors?.merchant_settings?.use_own_tax_id}
												helperText={
													errors?.merchant_settings?.use_own_tax_id
														? (errors?.merchant_settings?.use_own_tax_id?.message as string)
														: null
												}
												fullWidth
											>
												<MenuItem
													id={createElementId([
														MERCHANT_PROFILE,
														FORM_CONTROL,
														TEXTFIELD,
														DROPDOWN,
														MENU_ITEM,
														'yes'
													])}
													value={'YES'}
												>
													Yes
												</MenuItem>
												<MenuItem
													id={createElementId([
														MERCHANT_PROFILE,
														FORM_CONTROL,
														TEXTFIELD,
														DROPDOWN,
														MENU_ITEM,
														'no'
													])}
													value={'NO'}
												>
													No
												</MenuItem>
											</TextField>
										</FormControl>
									)}
								</Grid>
								<Grid item xs={10} sm={10} md={2.3} lg={1.5} xl={1.5}>
									<FormControl variant={'standard'} fullWidth>
										<TextField
											id={createElementId([
												MERCHANT_PROFILE,
												FORM_CONTROL,
												TEXTFIELD,
												DROPDOWN,
												SELECT,
												index.toString(),
												'tax_id_type'
											])}
											{...register(`tax_identifiers.${index}.tax_id_type`)}
											select
											required={useTaxNumber}
											disabled={!useTaxNumber}
											onChange={e => handleTaxidTypeChange(e, index)}
											defaultValue={
												taxIDType ? getValues(`tax_identifiers.${index}.tax_id_type`) : 'NoType'
											}
											label="Tax ID Type"
											error={!!errors?.tax_identifiers?.[index]?.tax_id_type && useTaxNumber}
											helperText={
												errors?.tax_identifiers?.[index]?.tax_id_type && useTaxNumber
													? (errors?.tax_identifiers?.[index]?.tax_id_type?.message as string)
													: null
											}
											fullWidth
										>
											<MenuItem
												id={createElementId([
													MERCHANT_PROFILE,
													FORM_CONTROL,
													TEXTFIELD,
													DROPDOWN,
													MENU_ITEM,
													index.toString(),
													'vat'
												])}
												value={'VAT'}
											>
												VAT
											</MenuItem>
											<MenuItem
												id={createElementId([
													MERCHANT_PROFILE,
													FORM_CONTROL,
													TEXTFIELD,
													DROPDOWN,
													MENU_ITEM,
													index.toString(),
													'eori'
												])}
												value={'EORI'}
											>
												EORI
											</MenuItem>
											<MenuItem
												id={createElementId([
													MERCHANT_PROFILE,
													FORM_CONTROL,
													TEXTFIELD,
													DROPDOWN,
													MENU_ITEM,
													index.toString(),
													'ioss'
												])}
												value={'IOSS'}
											>
												IOSS
											</MenuItem>
											<MenuItem
												id={createElementId([
													MERCHANT_PROFILE,
													FORM_CONTROL,
													TEXTFIELD,
													DROPDOWN,
													MENU_ITEM,
													index.toString(),
													'tin'
												])}
												value={'TIN'}
											>
												TIN
											</MenuItem>
											<MenuItem
												id={createElementId([
													MERCHANT_PROFILE,
													FORM_CONTROL,
													TEXTFIELD,
													DROPDOWN,
													MENU_ITEM,
													index.toString(),
													'voec'
												])}
												value={'VOEC'}
											>
												VOEC
											</MenuItem>
											<MenuItem
												id={createElementId([
													MERCHANT_PROFILE,
													FORM_CONTROL,
													TEXTFIELD,
													DROPDOWN,
													MENU_ITEM,
													index.toString(),
													'hmrc'
												])}
												value={'HMRC'}
											>
												HMRC
											</MenuItem>
											<MenuItem
												id={createElementId([
													MERCHANT_PROFILE,
													FORM_CONTROL,
													TEXTFIELD,
													DROPDOWN,
													MENU_ITEM,
													index.toString(),
													'notype'
												])}
												disabled={index > 0}
												value={'NoType'}
											>
												Tax ID Type
											</MenuItem>
										</TextField>
									</FormControl>
								</Grid>
								<Grid item xs={10} sm={10} md={2.5} lg={2} xl={2}>
									<FormControl variant={'standard'} fullWidth>
										<TextField
											id={createElementId([
												MERCHANT_PROFILE,
												FORM_CONTROL,
												TEXTFIELD,
												index.toString(),
												'tax_id'
											])}
											required={useTaxNumber}
											disabled={!useTaxNumber}
											{...register(`tax_identifiers.${index}.tax_id`, {
												validate: {
													validTaxID: e => {
														if (useTaxNumber) {
															const isValid = handleTaxIDTypeLength(e, index);
															return isValid || 'Tax Id is Invalid';
														} else {
															return true;
														}
													}
												}
											})}
											defaultValue={taxID}
											onChange={e => handleTaxIDChange(e, index)}
											label="Tax Identifier Number"
											error={!!errors?.tax_identifiers?.[index]?.tax_id && useTaxNumber}
											helperText={
												errors?.tax_identifiers?.[index]?.tax_id && useTaxNumber
													? (errors?.tax_identifiers?.[index]?.tax_id?.message as string)
													: null
											}
											fullWidth
										/>
									</FormControl>
								</Grid>
								<Grid item xs={10} sm={10} md={2.5} lg={1.5} xl={1.5}>
									<FormControl variant={'standard'} fullWidth>
										<TextField
											id={createElementId([
												MERCHANT_PROFILE,
												FORM_CONTROL,
												TEXTFIELD,
												index.toString(),
												'issuing_country'
											])}
											required={useTaxNumber}
											disabled={!useTaxNumber}
											{...register(`tax_identifiers.${index}.issuing_country`, {
												// required: 'Required',
												pattern: {
													value: /^[a-z][A-Z]{1}$/i,
													message: 'Must be 2 letter code'
												}
											})}
											defaultValue={issuingCountry[index]}
											onChange={e => handleIssuingCountryChange(e, index)}
											label="Issuing Country"
											error={!!errors?.tax_identifiers?.[index]?.issuing_country && useTaxNumber}
											helperText={
												errors?.tax_identifiers?.[index]?.issuing_country && useTaxNumber
													? (errors?.tax_identifiers?.[index]?.issuing_country
															?.message as string)
													: null
											}
											fullWidth
										/>
									</FormControl>
								</Grid>
								{index === fields.length - 1 && index < 7 ? (
									<Grid item xs={10} sm={10} md={1.3} lg={1.5} xl={1.5}>
										<Button
											id={createElementId([
												MERCHANT_PROFILE,
												FORM_CONTROL,
												BUTTON,
												index.toString(),
												'add_item'
											])}
											fullWidth
											variant={'outlined'}
											disabled={!useTaxNumber}
											onClick={() => {
												setTaxIDType(['NoType']);
												setValue('merchant_settings.use_own_tax_id', true);
												append({
													tax_id: '',
													tax_id_type: 'NoType',
													issuing_country: ''
												});
											}}
											style={{ marginBottom: '30px', height: '75%' }}
										>
											Add Item
										</Button>
									</Grid>
								) : (
									<Grid item xs={10} sm={10} md={1.5} lg={1.5} xl={1.5}></Grid>
								)}
							</Grid>
						))}
						<Grid container spacing={2} justifyContent="center" alignContent="center">
							<Grid item xs={10} sm={10} md={10} lg={8} xl={8}>
								<AddressAutoComplete
									setMerchantAddressDetails={setMerchantAddressDetails}
									// shipment_type={''}
								></AddressAutoComplete>
							</Grid>
						</Grid>
						<Grid container spacing={2} justifyContent="center" alignContent="center">
							<Grid item xs={10} sm={10} md={5} lg={4} xl={4}>
								<TextField
									id={createElementId([
										MERCHANT_PROFILE,
										FORM_CONTROL,
										TEXTFIELD,
										SEND_FROM,
										'merchant_street_1'
									])}
									required
									label="Address Line 1"
									{...register('merchant_street_1', { required: 'Required' })}
									helperText={
										errors?.merchant_street_1 ? (errors.merchant_street_1.message as string) : null
									}
									fullWidth
									value={watch('merchant_street_1') || ''}
									onChange={handleAddressChange}
									inputProps={{ 'data-hj-allow': true }}
								/>
							</Grid>
							<Grid item xs={10} sm={10} md={5} lg={4} xl={4} mb={2}>
								<TextField
									id={createElementId([
										MERCHANT_PROFILE,
										FORM_CONTROL,
										TEXTFIELD,
										SEND_FROM,
										'merchant_street_2'
									])}
									label="Address Line 2"
									fullWidth
									{...register('merchant_street_2', {})}
									helperText="Apartment, building, floor(optional)"
									value={watch('merchant_street_2') || ''}
									onChange={handleAddressChange}
									inputProps={{ 'data-hj-allow': true }}
								/>
							</Grid>
						</Grid>
						<Grid container spacing={2} justifyContent="center" alignContent="center">
							<Grid item xs={10} sm={10} md={2} lg={2} xl={2}>
								<TextField
									id={createElementId([
										MERCHANT_PROFILE,
										FORM_CONTROL,
										TEXTFIELD,
										SEND_FROM,
										'merchant_city_locality'
									])}
									required
									label="City"
									fullWidth
									{...register('merchant_city_locality', {
										required: 'Required'
									})}
									error={!!errors?.merchant_city_locality}
									helperText={
										errors?.merchant_city_locality
											? (errors.merchant_city_locality.message as string)
											: null
									}
									value={watch('merchant_city_locality') || ''}
									onChange={handleAddressChange}
									inputProps={{ 'data-hj-allow': true }}
								/>
							</Grid>
							<Grid item xs={10} sm={10} md={2} lg={2} xl={2}>
								<TextField
									id={createElementId([
										MERCHANT_PROFILE,
										FORM_CONTROL,
										TEXTFIELD,
										SEND_FROM,
										'merchant_state_province'
									])}
									required
									label="Province"
									fullWidth
									{...register('merchant_state_province', {
										required: 'Required',
										pattern: {
											value: /^[a-z][A-Z]{1}$/i,
											message: 'Invalid input. Province must be a 2 letter code'
										}
									})}
									error={!!errors?.merchant_state_province}
									helperText={
										errors?.merchant_state_province
											? (errors.merchant_state_province.message as string)
											: null
									}
									value={watch('merchant_state_province') || ''}
									onChange={handleAddressChange}
									inputProps={{ 'data-hj-allow': true }}
								/>
							</Grid>
							<Grid item xs={10} sm={10} md={3} lg={2} xl={2}>
								<TextField
									id={createElementId([
										MERCHANT_PROFILE,
										FORM_CONTROL,
										TEXTFIELD,
										SEND_FROM,
										'merchant_zip_postal'
									])}
									required
									label="Postal Code"
									fullWidth
									{...register('merchant_zip_postal', {
										required: 'Required'
									})}
									error={!!errors?.merchant_zip_postal}
									helperText={
										errors?.merchant_zip_postal
											? (errors.merchant_zip_postal.message as string)
											: null
									}
									value={watch('merchant_zip_postal') || ''}
									onChange={handleAddressChange}
									inputProps={{ 'data-hj-allow': true }}
								/>
							</Grid>
							<Grid item xs={10} sm={10} md={3} lg={2} xl={2} mb={2}>
								<TextField
									id={createElementId([
										MERCHANT_PROFILE,
										FORM_CONTROL,
										TEXTFIELD,
										SEND_FROM,
										'merchant_country'
									])}
									required
									label="Country"
									fullWidth
									{...register('merchant_country', {
										required: 'Required',
										pattern: {
											value: /^[a-z][A-Z]{1}$/i,
											message: 'Invalid input. Country must be 2 letter code'
										}
									})}
									error={!!errors?.merchant_country}
									helperText={
										errors?.merchant_country ? (errors.merchant_country.message as string) : null
									}
									onChange={handleAddressChange}
									value={watch('merchant_country') || ''}
									inputProps={{ 'data-hj-allow': true }}
								/>

								{/* <Autocomplete
										fullWidth
										options={countries.map((item: { code: string; name: string }) => ({
											name: `${item.name} ${item.code}`,
											code: item.code
										}))}
										getOptionLabel={option => option.name as string}
										onChange={handleCountryAddressChange}
										value={countries.find(item => item.code === watch('merchant_country')) || null}
										renderInput={params => (
											<TextField
												{...params}
												// required
												label="Country"
												// onChange={e => setValue('merchant_country', countryCode)}
												// onChange={handleAddressChange}
												error={!!errors?.merchant_country}
												helperText={
													errors?.merchant_country
														? (errors.merchant_country.message as string)
														: null
												}
												inputProps={{ 'data-hj-allow': true }}
											/>
										)}
									/> */}
							</Grid>
						</Grid>
						{/* <Grid container spacing={2} justifyContent="center" alignContent="center">
								<Grid alignSelf={'center'} item>
									Drop-Off
									<Switch
										name="merchant_dropoff"
										checked={checked.drop_off}
										// inputProps={{ "aria-label": "controlled" }}
										onChange={handleCheckBoxChange}
									/>{' '}
									Collect
									<Switch
										name="merchant_collect"
										checked={checked.collect}
										// inputProps={{ "aria-label": "controlled" }}
										onChange={handleCheckBoxChange}
									/>{' '}
								</Grid>
							</Grid> */}
						<Grid container spacing={2} justifyContent="center" alignContent="center">
							<Grid item>
								<Button
									id={createElementId([MERCHANT_PROFILE, FORM_CONTROL, BUTTON, 'submit'])}
									variant="contained"
									type="submit"
								>
									Update Profile
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</form>
			</Grid>

			<Snackbar
				open={openSnackBar}
				autoHideDuration={3000}
				onClose={handleAlertClose}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			>
				<Alert onClose={handleAlertClose} severity={apiResponse.type} sx={{ width: '100%' }}>
					{apiResponse.message}
				</Alert>
			</Snackbar>
			<Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
		</Grid>
	);
}
