import { createSlice } from '@reduxjs/toolkit';
export interface AddressState {
	address_to_street_1: string;
	address_to_zip_postal: string;
	address_to_city_locality: string;
	address_to_state_province: string;
	address_to_country: string;
}
const initialState: AddressState = {
	address_to_street_1: '',
	address_to_zip_postal: '',
	address_to_city_locality: '',
	address_to_state_province: '',
	address_to_country: ''
};
const addressSlice = createSlice({
	name: 'address',
	initialState,
	reducers: {
		setAddress: (state, action) => {
			switch (action.payload.type) {
				case 'SETADDRESS':
					return { ...action.payload.address_fields };
				case 'RESET':
					return initialState;
				default:
					break;
			}
		}
	}
});
export const { setAddress } = addressSlice.actions;
export default addressSlice.reducer;
