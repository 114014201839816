import {
	Alert,
	AlertColor,
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Grid,
	Modal,
	Snackbar,
	TextField,
	Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { axiosConfig } from '../../constants/axios';
import { BUTTON, createElementId, FORM_CONTROL, LABEL, MANAGE_USERS, MODAL, TEXTFIELD } from '../../constants/id';
import { MerchantRecord } from '../../types/merchant';
export default function UserDetails(props: {
	merchant: MerchantRecord;
	show: boolean;
	onModalClosed: (updates: boolean) => void;
	newUsers: any;
}) {
	const { show, onModalClosed, merchant, newUsers } = props;
	const [saving, setSaving] = useState(false);
	const [showSnackbar, setShowSnackbar] = useState(false);
	const [apiResponse, setApiResponse] = useState<{
		type: AlertColor;
		message: string;
	}>({ type: 'success', message: '' });
	const axiosInstance = axiosConfig();

	const {
		reset,
		register,
		handleSubmit,
		formState: { errors }
	} = useForm();

	async function handleUserSave(values: FieldValues) {
		setSaving(true);
		try {
			const response = await axiosInstance.post('/user/add', {
				partitionKey: merchant.partitionKey,
				merchant_id: merchant.partitionKey,
				merchant_name: merchant.merchant_name,
				user_email: values.user_email,
				user_name: values.user_name
			});

			if (response.status === 201) {
				setApiResponse({ type: 'success', message: response.data.message });
				setShowSnackbar(true);
			}
			newUsers();
		} catch (error: any) {
			setApiResponse({
				type: 'error',
				message: error.response.data.data || error.response.data.data.message
			});
			setShowSnackbar(true);
		}
	}

	const resetModalStyle = {
		position: 'absolute' as 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 'auto',
		height: 'auto',
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		pt: 4,
		px: 4,
		pb: 3
	};

	useEffect(() => {
		if (show) {
			reset();
		}
	}, [show, reset]);

	const handleClose = () => {
		onModalClosed(false);
		setShowSnackbar(false);
		setSaving(false);
	};

	return (
		<Modal
			open={show}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box>
				<Box sx={resetModalStyle} style={{ border: 'none', boxShadow: '1px 1px solid gray' }}>
					<Typography
						id={createElementId([MANAGE_USERS, MODAL, FORM_CONTROL, LABEL, 'user_name'])}
						variant="h6"
						component="h2"
					>
						Add User To: {merchant.merchant_name}
					</Typography>
					<Box sx={{ pt: 2 }}>
						<form onSubmit={handleSubmit(handleUserSave)}>
							<Grid container sx={{ display: 'flex', flexDirection: 'column' }}>
								<TextField
									id={createElementId([MANAGE_USERS, MODAL, FORM_CONTROL, TEXTFIELD, 'user_name'])}
									label="Full Name"
									{...register('user_name', {
										required: 'Required'
									})}
									error={errors.user_name ? true : false}
									helperText={errors.user_name ? (errors.user_name.message as string) : ''}
									defaultValue=""
									fullWidth
									sx={{ mt: 2 }}
								/>
								<TextField
									id={createElementId([MANAGE_USERS, MODAL, FORM_CONTROL, TEXTFIELD, 'user_email'])}
									label="Email Address"
									{...register('user_email', {
										required: 'Required',
										pattern: {
											value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
											message: 'Invalid email address'
										}
									})}
									error={errors.user_email ? true : false}
									helperText={errors.user_email ? (errors.user_email.message as string) : ''}
									defaultValue=""
									fullWidth
									sx={{ mt: 2 }}
								/>
							</Grid>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'flex-end',
									flexDirection: 'row',
									pt: 2
								}}
							>
								<Button
									id={createElementId([MANAGE_USERS, MODAL, FORM_CONTROL, BUTTON, 'cancel'])}
									onClick={() => handleClose()}
									sx={{ mr: 1 }}
									color="primary"
								>
									Cancel
								</Button>
								<Button
									id={createElementId([MANAGE_USERS, MODAL, FORM_CONTROL, BUTTON, 'save'])}
									type="submit"
									sx={{ mr: 1 }}
									color="primary"
								>
									Save
								</Button>
							</Box>
						</form>
					</Box>
				</Box>

				<Snackbar
					open={showSnackbar}
					autoHideDuration={2000}
					onClose={handleClose}
					anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
					sx={{ zIndex: 11000 }}
				>
					<Alert onClose={handleClose} severity={apiResponse.type} sx={{ width: '100%' }}>
						{apiResponse.message}
					</Alert>
				</Snackbar>
				<Backdrop sx={{ color: '#fff', zIndex: 10000 }} open={saving}>
					<CircularProgress color="inherit" />
				</Backdrop>
			</Box>
		</Modal>
	);
}
