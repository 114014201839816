import { Alert, Backdrop, Button, CircularProgress, Grid, Snackbar, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Navigate, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { axiosConfig } from '../../constants/axios';
import { SnackAlert } from '../../types/util';

export default function CreateBooking() {
	const [loading, setLoading] = useState(false);
	const [openSnackBar, setOpenSnackBar] = useState(false);
	const [snackAlert, setSnackAlert] = useState<SnackAlert>({ type: 'success', message: '' });
	const navigate = useNavigate();
	const user = JSON.parse(sessionStorage.getItem('user') as string);
	const { register, getValues } = useForm({
		mode: 'all',
		defaultValues: {
			booking_id: uuidv4(),
			booking_awb_prefix: '',
			booking_big_boxes: [],
			booking_mawb_number: '',
			booking_office: 'DFM',
			booking_flight_1: '',
			booking_flight_2: '',
			booking_flight_3: '',
			booking_destination_1: '',
			booking_destination_2: '',
			booking_destination_3: '',
			booking_date_departure: '2024-',
			booking_date_arrival: '2024-',
			booking_destination_final: 'JFK',
			booking_destination_final_code: '4701',
			booking_entry_clerk: 'Max Johnston',
			booking_place_of_issue: 'CPT',
			booking_place_of_issue_date: '2024-',
			booking_discharge_port: 'JFK',
			booking_depot_code: '',
			booking_port_of_loading: 'CPT',
			booking_port_of_exit: 'DFM',
			booking_export_country: 'ZA',
			booking_destination: 'US',
			week_number: DateTime.local().weekNumber.toString()
		}
	});
	const axiosInstance = axiosConfig();

	const handleAlertClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenSnackBar(false);
	};

	const handleSubmit = async () => {
		setLoading(true);
		try {
			const response = await axiosInstance.post('/booking/booking', getValues());
			navigate('/customs/dashboard');
			setLoading(false);
		} catch (error) {
			if (axios.isAxiosError(error) && error.response) {
				setSnackAlert({ type: 'error', message: error.response.data.error });
				setOpenSnackBar(true);
			} else {
				setSnackAlert({ type: 'error', message: 'Error: An error has occurred' });
			}
			console.log(error);
			setLoading(false);
		}
		setLoading(false);
	};

	return (
		<>
			{!user.admin_access ? (
				<Navigate to="/" state={{ type: 'error', message: 'Access Denied!' }} />
			) : (
				<div
					style={{
						display: 'block',
						justifyContent: 'center',
						alignContent: 'center',
						marginBottom: '60px',
						maxWidth: 800,
						marginLeft: 'auto',
						marginRight: 'auto',
						paddingBottom: '100px'
					}}
				>
					{!loading ? (
						<Grid container alignItems="center" direction="column" marginTop={8} rowSpacing={2}>
							<Typography variant="h5" mb={5}>
								Create Booking
							</Typography>
							<Grid
								container
								direction="row"
								spacing={2}
								alignItems={'center'}
								justifyContent={'center'}
								mb={2}
							>
								<Grid item xs={10} sm={10} md={12} lg={4} xl={4}>
									<TextField label={'AWB Prefix'} fullWidth {...register('booking_awb_prefix')} />
								</Grid>
								<Grid item xs={10} sm={10} md={12} lg={4} xl={4}>
									<TextField label={'MAWB'} fullWidth {...register('booking_mawb_number')} />
								</Grid>
								<Grid item xs={10} sm={10} md={12} lg={4} xl={4}>
									<TextField label={'Customs Office'} fullWidth {...register('booking_office')} />
								</Grid>
							</Grid>
							<Grid
								container
								direction="row"
								spacing={2}
								alignItems={'center'}
								justifyContent={'center'}
								mb={2}
							>
								<Grid item xs={10} sm={10} md={12} lg={4} xl={4}>
									<TextField label={'Flight 1'} fullWidth {...register('booking_flight_1')} />
								</Grid>
								<Grid item xs={10} sm={10} md={12} lg={4} xl={4}>
									<TextField label={'Flight 2'} fullWidth {...register('booking_flight_2')} />
								</Grid>
								<Grid item xs={10} sm={10} md={12} lg={4} xl={4}>
									<TextField label={'Flight 3'} fullWidth {...register('booking_flight_3')} />
								</Grid>
							</Grid>
							<Grid
								container
								direction="row"
								spacing={2}
								alignItems={'center'}
								justifyContent={'center'}
								mb={2}
							>
								<Grid item xs={10} sm={10} md={12} lg={4} xl={4}>
									<TextField
										label={'Destination 1'}
										fullWidth
										{...register('booking_destination_1')}
									/>
								</Grid>
								<Grid item xs={10} sm={10} md={12} lg={4} xl={4}>
									<TextField
										label={'Destination 2'}
										fullWidth
										{...register('booking_destination_2')}
									/>
								</Grid>
								<Grid item xs={10} sm={10} md={12} lg={4} xl={4}>
									<TextField
										label={'Destination 3'}
										fullWidth
										{...register('booking_destination_3')}
									/>
								</Grid>
							</Grid>
							<Grid
								container
								direction="row"
								spacing={2}
								alignItems={'center'}
								justifyContent={'center'}
								mb={2}
							>
								<Grid item xs={10} sm={10} md={12} lg={4} xl={4}>
									<TextField
										label={'Shipment Date'}
										fullWidth
										{...register('booking_date_departure')}
									/>
								</Grid>
								<Grid item xs={10} sm={10} md={12} lg={4} xl={4}>
									<TextField
										label={'Date of Arrival'}
										fullWidth
										{...register('booking_date_arrival')}
									/>
								</Grid>
								<Grid item xs={10} sm={10} md={12} lg={4} xl={4}>
									<TextField label={'Week Number'} fullWidth {...register('week_number')} />
								</Grid>
							</Grid>
							<Grid
								container
								direction="row"
								spacing={2}
								alignItems={'center'}
								justifyContent={'center'}
								mb={2}
							>
								<Grid item xs={10} sm={10} md={12} lg={6} xl={6}>
									<TextField
										label={'Final Destination'}
										fullWidth
										{...register('booking_destination_final')}
									/>
								</Grid>
								<Grid item xs={10} sm={10} md={12} lg={6} xl={6}>
									<TextField
										label={'Final Destination Code'}
										fullWidth
										{...register('booking_destination_final_code')}
									/>
								</Grid>
							</Grid>
							<Grid
								container
								direction="row"
								spacing={2}
								alignItems={'center'}
								justifyContent={'center'}
								mb={2}
							>
								<Grid item xs={10} sm={10} md={12} lg={4} xl={4}>
									<TextField label={'Entry Clerk'} fullWidth {...register('booking_entry_clerk')} />
								</Grid>
								<Grid item xs={10} sm={10} md={12} lg={4} xl={4}>
									<TextField
										label={'Place of Issue'}
										fullWidth
										{...register('booking_place_of_issue')}
									/>
								</Grid>
								<Grid item xs={10} sm={10} md={12} lg={4} xl={4}>
									<TextField
										label={'Place of Issue Date'}
										fullWidth
										{...register('booking_place_of_issue_date')}
									/>
								</Grid>
							</Grid>
							<Grid
								container
								direction="row"
								spacing={2}
								alignItems={'center'}
								justifyContent={'center'}
								mb={2}
							>
								<Grid item xs={10} sm={10} md={12} lg={6} xl={3}>
									<TextField
										label={'Discharge Port'}
										fullWidth
										{...register('booking_discharge_port')}
									/>
								</Grid>
								<Grid item xs={10} sm={10} md={12} lg={6} xl={3}>
									<TextField label={'Depot Code'} fullWidth {...register('booking_depot_code')} />
								</Grid>
								<Grid item xs={10} sm={10} md={12} lg={6} xl={3}>
									<TextField
										label={'Port of Loading'}
										fullWidth
										{...register('booking_port_of_loading')}
									/>
								</Grid>
								<Grid item xs={10} sm={10} md={12} lg={6} xl={3}>
									<TextField label={'Port of Exit'} fullWidth {...register('booking_port_of_exit')} />
								</Grid>
							</Grid>
							<Grid
								container
								direction="row"
								spacing={2}
								alignItems={'center'}
								justifyContent={'center'}
								mb={2}
							>
								<Grid item xs={10} sm={10} md={12} lg={6} xl={6}>
									<TextField
										label={'Country of export'}
										fullWidth
										{...register('booking_export_country')}
									/>
								</Grid>
								<Grid item xs={10} sm={10} md={12} lg={6} xl={6}>
									<TextField
										label={'Destination Country'}
										fullWidth
										{...register('booking_destination')}
									/>
								</Grid>
							</Grid>
							<Grid container direction="row" spacing={2} alignItems={'center'} justifyContent={'center'}>
								<Grid item xs={10} sm={10} md={12} lg={6} xl={6} alignSelf={'center'}>
									<Button color="primary" variant="contained" fullWidth onClick={handleSubmit}>
										Book
									</Button>
								</Grid>
							</Grid>
						</Grid>
					) : (
						<Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
							<CircularProgress color="inherit" />
						</Backdrop>
					)}
					<Snackbar
						open={openSnackBar}
						autoHideDuration={2000}
						onClose={handleAlertClose}
						anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
					>
						<Alert onClose={handleAlertClose} severity={snackAlert.type} sx={{ width: '100%' }}>
							{snackAlert.message}
						</Alert>
					</Snackbar>
				</div>
			)}
		</>
	);
}
