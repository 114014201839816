const currencyMap: { [key: string]: string } = {
	ZAR: 'R',
	GBP: '£',
	EUR: '€',
	USD: '$',
	CAD: 'C$',
	AUD: 'A$'
};

export function currencyToSymbolConversion(currency: string) {
	return currencyMap[currency] || '';
}
