import React from 'react';
import TrackerFooter from './TrackerFooter';
import TrackerHeader from './TrackerHeader';

interface AppProps {
	children: React.ReactNode;
}

export default function TrackerLayout({ children }: AppProps) {
	return (
		<div>
			<TrackerHeader />
			{children}
			<TrackerFooter />
		</div>
	);
}
