import { AppBar, styled, Toolbar } from '@mui/material';

const StyledAppBar = styled(AppBar)({
	backgroundColor: '#007BC4',
	position: 'sticky'
});

export default function TrackerHeader() {
	return (
		<StyledAppBar sx={{ height: '25px' }}>
			<Toolbar></Toolbar>
		</StyledAppBar>
	);
}
