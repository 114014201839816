import { createSlice } from '@reduxjs/toolkit';


const initialState = {
	alertBoolean: 'false',
  };


const alertSlice = createSlice({
	name: 'alert',
	initialState,
	reducers: {
		setAlertBoolean: (state = initialState, action: any) => {
			state.alertBoolean = action.payload
		  }
	} 
});


export const { setAlertBoolean } = alertSlice.actions;
export default alertSlice.reducer;
