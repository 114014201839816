const convertWeight = (weight: number): number => {
	const convertedWeight = weight * 0.0283495;
	return convertedWeight < 1 ? parseFloat(convertedWeight.toFixed(2)) : Math.round(convertedWeight);
};

export function kgToOzConversion(kg: number): number {
	return convertWeight(kg);
};

export function ozToKgConversion(oz: number): number {
	return oz / 0.0283495;
};
