/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:3d148621-bb75-4d86-b2c2-6724e1ca885c",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_5GtcNwMZe",
    "aws_user_pools_web_client_id": "60j7ass24tlqi9rqvaept11eca",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "NAME",
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "comms-invoice-pdf92004-prod",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
