import {
	Alert,
	Backdrop,
	Button,
	CircularProgress,
	Grid,
	Paper,
	Snackbar,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import ContentContainer from '../../components/Layout/ContentContainer';
import { axiosConfig } from '../../constants/axios';
import {
	BUTTON,
	createElementId,
	HEADING,
	LABEL,
	MANAGE_USERS,
	TABLE,
	TABLE_BODY,
	TABLE_HEAD
} from '../../constants/id';
import { UserMerchantRecord, UserRecord } from '../../types/user';
import { MerchantRecord } from '../../types/merchant';
import UserDetails from './UserDetails';
import { useMerchant } from '../../context/MerchantContext';

export default function UsersDashboard() {
	const { merchantProfile } = useMerchant();
	const [loading, setLoading] = useState(false);
	const [users, setUsers] = useState<UserRecord[]>();
	const [showUserModal, setShowUserModal] = useState(false);
	const [showSnackbar, setShowSnackbar] = useState(false);
	const axiosInstance = axiosConfig();

	async function getUsers() {
		setLoading(true);

		try {
			const response = await axiosInstance.post(`/user/merchantUsers?merchantId=${merchantProfile.partitionKey}`);
			setLoading(false);
			setUsers(response.data.data);
		} catch (ex) {
			setLoading(false);
		}
	}

	function handleAddUserClicked() {
		setShowSnackbar(false);
		setShowUserModal(true);
	}

	function handleModalClosed(updated: boolean) {
		if (updated) {
			setShowSnackbar(true);
			getUsers();
		}

		setShowUserModal(false);
	}

	useEffect(() => {
		if (merchantProfile) {
			getUsers();
		}
	}, [merchantProfile]);


	return (
		<ContentContainer>
			<Grid container direction="row" justifyContent="space-between" alignItems="flex-start" mt={4}>
				<Grid item xs={12} sm={12} md={6} lg={8} xl={8} alignContent="center" justifyContent="center" >
					<Typography
						variant="h3"
						id={createElementId([MANAGE_USERS, HEADING, 'manage_users'])}
					>
						Manage Users
					</Typography>
					
				</Grid>
			</Grid>
			<Grid
				container
				direction="row"
				justifyContent="flex-start"
				spacing={2}
				sx={{ marginBottom: '20px', marginTop: '30px' }}
			>
				<Grid item>
					<Button
						id={createElementId([MANAGE_USERS, BUTTON, 'add_user'])}
						color="primary"
						variant="contained"
						sx={{ borderRadius: '30px' }}
						onClick={handleAddUserClicked}
					>
						Add User
					</Button>
				</Grid>
			</Grid>
			<TableContainer component={Paper} sx={{ backgroundColor: '#fff', boxShadow: 3, mb: 5 }}>
				<Table>
					<TableHead>
						<Grid container sx={{ p: 2, fontSize: '1.2em' }}>
							<Typography id={createElementId([MANAGE_USERS, LABEL, 'users'])}>Users</Typography>
						</Grid>
						<TableRow>
							<TableCell
								id={createElementId([MANAGE_USERS, TABLE, TABLE_HEAD, 'name'])}
								sx={{ paddingLeft: 5, fontWeight: 'light', fontSize: '0.8em', color: 'gray' }}
							>
								Name
							</TableCell>
							<TableCell
								id={createElementId([MANAGE_USERS, TABLE, TABLE_HEAD, 'email'])}
								sx={{ fontWeight: 'light', fontSize: '0.8em', color: 'gray' }}
							>
								Email Address
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{users && users.length ? (
							users.map((user: UserRecord) => (
								<TableRow sx={{ borderBottom: 1, borderColor: '#dadada' }} key={user.sortKey}>
									<TableCell sx={{ paddingLeft: 5 }}>
										<Typography id={createElementId([MANAGE_USERS, TABLE, TABLE_BODY, 'name'])}>
											{user.user_name}
										</Typography>
									</TableCell>
									<TableCell>
										<Typography id={createElementId([MANAGE_USERS, TABLE, TABLE_BODY, 'email'])}>
											{user.user_email}
										</Typography>
									</TableCell>
								</TableRow>
							))
						) : (
							<TableRow>
								<TableCell colSpan={12}>
									<Grid container justifyContent="center" alignItems="center">
									{ !loading && (
										<Typography id={createElementId([MANAGE_USERS, TABLE, TABLE_BODY, 'no_users'])}>
											No users added yet!
										</Typography>
									)}
									</Grid>
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<UserDetails
				merchant={merchantProfile}
				show={showUserModal}
				onModalClosed={handleModalClosed}
				newUsers={getUsers}
			></UserDetails>
			<Snackbar
				open={showSnackbar}
				autoHideDuration={2000}
				onClose={() => setShowSnackbar(false)}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			>
				<Alert onClose={() => setShowSnackbar(false)} severity="success" sx={{ width: '100%' }}>
					User created!
				</Alert>
			</Snackbar>
			<Backdrop sx={{ color: '#fff', zIndex: 10000 }} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
		</ContentContainer>
	);
}
