import { Typography } from '@mui/material';

export default function LearnMore(props: any) {
	const { href, inline, style, label } = props;
	return inline ? (
		<a href={href} target="_blank" rel="noopener noreferrer" style={style}>
			<u>{label ?? 'Learn more.'}</u>
		</a>
	) : (
		<Typography variant={!!label ? 'body1':'h6' }>
			<a href={href} target="_blank" rel="noreferrer" style={{ color: '#50B4BD' }}>
				{label ?? 'Learn More-->'}
			</a>
		</Typography>
	);
}
