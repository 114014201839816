import { Alert, Grid, Typography, Snackbar, Button } from '@mui/material';
import { useRef, useState } from 'react';
import Retool from 'react-retool';
import { Navigate } from 'react-router-dom';
import { SnackAlert } from '../../types/util';

export default function CustomsShipments(props: any) {
	const user = JSON.parse(sessionStorage.getItem('user') as string);

	const [openSnackBar, setOpenSnackBar] = useState(false);
	const [snackAlert, setSnackAlert] = useState<SnackAlert>({ type: 'success', message: '' });

	const copyToClipboard = () => {
		const textToCopy =
			process.env.REACT_APP_ENVIRONMENT === 'prod'
				? (process.env.REACT_APP_RETOOL_PROD_PASSWORD as string)
				: (process.env.REACT_APP_RETOOL_DEV_PASSWORD as string);

		navigator.clipboard
			.writeText(textToCopy)
			.then(() => {
				setSnackAlert({ type: 'success', message: 'Text copied!' });
				setOpenSnackBar(true);
			})
			.catch(error => {
				console.error('Error copying text:', error);
				setSnackAlert({ type: 'error', message: 'Error: An error has occurred' });
			});
	};

	const handleAlertClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenSnackBar(false);
	};

	return (
		<>
			{!user.admin_access ? (
				<Navigate to="/" state={{ type: 'error', message: 'Access Denied!' }} />
			) : (
				<>
					<Grid container alignItems="center" direction="column">
						<Typography variant="h4" mt={5}>
							Customs Shipments
						</Typography>
						<Grid container sx={{ width: '80%' }} alignItems="center" direction="row" justifyContent="end">
							<Grid item>
								<Button color="primary" variant="contained" size="small" onClick={copyToClipboard}>
									Copy Password
								</Button>
							</Grid>
						</Grid>
					</Grid>

					<Grid container alignItems="center" direction="column">
						<Grid item width={'80%'} sx={{ mt: 5, mb: 5 }}>
							<Retool
								url={
									process.env.REACT_APP_ENVIRONMENT === 'prod'
										? process.env.REACT_APP_RETOOL_PROD
										: process.env.REACT_APP_RETOOL_DEV
								}
								height="700px"
							/>
						</Grid>
					</Grid>
				</>
			)}

			<Snackbar
				open={openSnackBar}
				autoHideDuration={2000}
				onClose={handleAlertClose}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			>
				<Alert onClose={handleAlertClose} severity={snackAlert.type} sx={{ width: '100%' }}>
					{snackAlert.message}
				</Alert>
			</Snackbar>
		</>
	);
}
