import {
	Alert,
	AlertColor,
	AlertTitle,
	Backdrop,
	Button,
	CircularProgress,
	Grid,
	Link,
	Snackbar,
	Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import FirstShipment from '../../components/Merchant/FirstShipment';
import ShipmentsTable from '../../components/Merchant/Shipment/ShipmentsTable';
import { axiosConfig } from '../../constants/axios';
import { useMerchant } from '../../context/MerchantContext';

interface LocationState {
	type: string;
	message: string;
}

export default function MerchantDashboard(props: any) {
	const navigate = useNavigate();
	const { merchantProfile } = useMerchant();
	const location = useLocation();
	const [shipments, setShipments] = useState([]);
	const [shipmentsCount, setShipmentsCount] = useState(0);
	const [loading, setLoading] = useState(true);
	const [openSnackBar, setOpenSnackBar] = useState(false);
	const [updateOpen, setUpdateOpen] = useState(true);
	const state = location.state as LocationState | undefined;
	const axiosInstance = axiosConfig();

	// Check if state exists and contains the 'message' property
	const message = state && state.message ? state.message : '';
	const [apiResponse, setApiResponse] = useState<{
		type: AlertColor;
		message: string;
	}>({ type: 'success', message: '' });

	const displayExporterNotice = () => {
		return merchantProfile.merchant_personal_id !== '' && merchantProfile.merchant_exporters_code === '';
	};

	const handleAlertClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpenSnackBar(false);
	};

	useEffect(() => {
		setLoading(true);
		axiosInstance
			.post('/shipment/getBatchedShipmentsByMerchant', {
				merchantId: merchantProfile.merchant_id,
				pageSize: 10,
				pageNumber: 0
			})
			.then(response => {
				setShipments(response.data.data);
				setShipmentsCount(response.data.count);
			})
			.catch(err => {
				setApiResponse({
					type: 'error',
					message: 'Error: failed to get shipments'
				});
				setOpenSnackBar(true);
				console.log(err);
			})
			.finally(() => setLoading(false)); // eslint-disable-line react-hooks/exhaustive-deps
	}, [merchantProfile]);

	return (
		<Grid container direction="row" alignContent="center" justifyContent="center" my={2}>
			<Grid item xs={12} px={2}>
				<Grid container>
					{merchantProfile?._id && !loading && (

						<Grid container direction="row">							

							<Grid
								item
								xs={12}
								sm={12}
								md={6}
								lg={8}
								xl={8}
								pl={2}
								mt={2}
								alignContent="center"
								justifyContent="center"
							>
								<Typography variant="h3">Dashboard</Typography>

							</Grid>
							{shipments.length !== 0 && !loading && (
								<Grid container direction="row">
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12} pl={2} pt={2}>
										<Button
											color="primary"
											variant="contained"
											sx={{ borderRadius: '30px' }}
											onClick={() => navigate('/shipment')}
										>
											Create Shipment
										</Button>
										<Link
											target={'_blank'}
											href={'https://www.tunl.to/'}
											variant="body2"
											sx={{ padding: '10px' }}
										>
											Get Quick Quote
										</Link>
									</Grid>
								</Grid>
							)}
						</Grid>
					)}
					{displayExporterNotice() && !loading && (
						<Grid container direction="column" pt={5}>
							<Grid item>
								<Grid container direction="column" justifyContent="center" alignItems="center">
									<Alert severity="warning">
										<AlertTitle>
											<strong>
												It seems you are exporting using your personal ID Number, please take
												note of the following:
											</strong>
										</AlertTitle>
										<ul>
											<li>
												Please be prepared for the courier to request a copy of your ID when
												they collect your shipments.
											</li>
											<li>Exporting with an ID number may delay the export process.</li>
											<li>
												Imports and exports via ID number are limited up to an annual total
												combined threshold of R150,000.
											</li>
											<li>
												It is so much easier to do this with an exporters code (and it's a quick
												and easy application) -{' '}
												<a
													href="https://help.tunl.to/en/article/exporters-code-g9xog2/#:~:text=to%20by%20TUNL.-,Option%203,-%3A%0AThrough%20the"
													rel="noreferrer"
													target="_blank"
												>
													Apply Here
												</a>
												.
											</li>
										</ul>
									</Alert>
								</Grid>
							</Grid>
						</Grid>
					)}

					<Grid
						container
						direction="column"
						justifyContent="space-between"
						sx={{ marginBottom: '20px', marginTop: '30px', width: '100%' }}
					>
						{!loading && (
							<Grid item width="100%">
								{shipments.length === 0 ? (
									<FirstShipment merchant={merchantProfile} />
								) : (
									<ShipmentsTable
										merchantId={merchantProfile.merchant_id}
										shipmentsCount={shipmentsCount}
										shipments={shipments}
									></ShipmentsTable>
								)}
							</Grid>
						)}
					</Grid>
				</Grid>
				<Snackbar
					open={openSnackBar}
					autoHideDuration={2000}
					onClose={handleAlertClose}
					anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				>
					<Alert onClose={handleAlertClose} severity={apiResponse.type} sx={{ width: '100%' }}>
						{apiResponse.message}
					</Alert>
				</Snackbar>

				{/* alert for profile updated */}
				<Snackbar
					open={message !== '' ? updateOpen : !updateOpen}
					autoHideDuration={2000}
					onClose={() => setUpdateOpen(false)}
					anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				>
					<Alert onClose={() => setUpdateOpen(false)} sx={{ width: '100%' }}>
						{message}
					</Alert>
				</Snackbar>
				{/* alert for profile updated */}
				<Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
					<CircularProgress color="inherit" />
				</Backdrop>
			</Grid>
		</Grid>
	);
}
