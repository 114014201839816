import { createSlice } from '@reduxjs/toolkit';

export interface MerchantState {
	admin_access: boolean | null;
	merchant_id: string;
	merchant_name: string;
	merchant_email: string;
	merchant_phone: string;
	merchant_street_1: string;
	merchant_street_2: string;
	merchant_city_locality: string;
	merchant_state_province: string;
	merchant_country: string;
	merchant_zip_postal: string;
	merchant_personal_id: string;
	merchant_exporters_code: string;
	merchant_vat_number: string;
	merchant_collect: boolean;
	merchant_dropoff: boolean;
	merchant_store: string;
	merchant_shopify_domain?: string | null;
	merchant_user: string;
}

const initialState: MerchantState = {
	admin_access: null,
	merchant_id: '',
	merchant_name: '',
	merchant_email: '',
	merchant_phone: '',
	merchant_street_1: '',
	merchant_street_2: '',
	merchant_city_locality: '',
	merchant_state_province: '',
	merchant_country: '',
	merchant_zip_postal: '',
	merchant_personal_id: '',
	merchant_exporters_code: '',
	merchant_vat_number: '',
	merchant_collect: true,
	merchant_dropoff: false,
	merchant_store: '',
	merchant_shopify_domain: null,
	merchant_user: '',
};

const merchantSlice = createSlice({
	name: 'merchant',
	initialState,
	reducers: {
		setMerchant: (state, action) => {
			return action.payload;
		}
	}
});

export const { setMerchant } = merchantSlice.actions;
export default merchantSlice.reducer;
