import {
	Alert,
	Backdrop,
	Box,
	Button,
	Checkbox,
	CircularProgress,
	Divider,
	FormControlLabel,
	FormGroup,
	Grid,
	Paper,
	Snackbar,
	Switch,
	TextField,
	Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import LearnMore from '../../components/Common/LearnMore';
import { SnackAlert } from '../../types/util';
import { Auth } from 'aws-amplify';

export default function SalesChannels(props: any) {
	const [loading, setLoading] = useState(false);
	const [openSnackBar, setOpenSnackBar] = useState(false);
	const [apiResponse, setApiResponse] = useState<SnackAlert>({ type: 'success', message: '' });
	const [merchant] = useState(JSON.parse(sessionStorage.getItem('merchant') as string));
	//const [merchant_settings, setMerchantSettings] = useState(JSON.parse(sessionStorage.getItem('merchant_settings') as string));
	const [shopify_carrier, setShopifyCarrier] = useState(merchant.merchant_settings?.shopify_carrier);
	const [connected, setConnected] = useState(false);
	const [betaTesterToggle, setBetaTesterToggle] = useState(false);
	

	const handleToggleChange = () =>{

		setBetaTesterToggle(!betaTesterToggle)
	}

	const {
		register,
		unregister,
		getValues,
		setError,
		clearErrors,
		formState: { errors },
		watch
	  } = useForm({ mode: 'all' });

	useEffect(() => {
		if (betaTesterToggle) {
		  register('ground');
		  register('fedex_international_priority_ddp');
		  register('upssaver_ddp');
		} else {
		  unregister('ground');
		  unregister('fedex_international_priority_ddp');
		  unregister('upssaver_ddp');
		  clearErrors('checkboxes');
		}
	  // eslint-disable-next-line react-hooks/exhaustive-deps
	  }, [betaTesterToggle]);

	  // Watch checkbox values
	const economyCheckBox = watch('ground');
	const fedexDDPCheckBox = watch('fedex_international_priority_ddp');
	const upsDDPCheckBox = watch('upssaver_ddp');
	const fedexDAPCheckBox = watch('fedex_international_priority_ddp');
	const upsDAPCheckBox = watch('upssaver_ddp');


	useEffect(() => {
	
	const formData = getValues();
		if (formData.beta_tester && (economyCheckBox || fedexDDPCheckBox || upsDDPCheckBox|| fedexDAPCheckBox || upsDAPCheckBox)){
			clearErrors('checkboxes');
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [economyCheckBox, fedexDDPCheckBox, upsDDPCheckBox, fedexDAPCheckBox, upsDAPCheckBox]);
	

	const handleSubmit = async () => {
		setLoading(true);
		try {
			if (!shopify_carrier) {
				const formData = getValues();
				const session = await Auth.currentSession();
				const token = session.getIdToken().getJwtToken();

				if (formData.beta_tester && (
					!formData.ground && 
					!formData.fedex_international_priority_ddp && 
					!formData.upssaver_ddp && 
					!formData.fedex_international_priority_dap && 
					!formData.upssaver_dap
				)) {
					setError('checkboxes', {
					  type: 'manual',
					  message: 'At least one service must be selected.',
					});
					setLoading(false);
					return;
				  }
				const response = await fetch(`${process.env.REACT_APP_API_BASEURL}/shopify/live-rates/`, {
					method: 'POST',
					body: JSON.stringify({
						...getValues(),
						merchant_id: merchant.partitionKey
					}),
					headers: {
						'Content-Type': 'application/json',
						Authorization: token
					}
				});
				const data = await response.json();
				if (data?.carrier_service?.active) {
					setApiResponse({ type: 'success', message: 'Success' });
					setOpenSnackBar(true);
					setShopifyCarrier(true);
					setConnected(true);
					sessionStorage.setItem(
						'merchant',
						JSON.stringify({
							...merchant,
							merchant_settings: {
								...merchant.merchant_settings,
								shopify_carrier: true
							}
						})
					);
					// sessionStorage.setItem(
					// 	'merchant_settings',
					// 	JSON.stringify({ ...merchant_settings, shopify_carrier: true })
					// );
				} else if (data?.errors?.base) {
					setApiResponse({
						type: 'error',
						message: data.errors.base.map((error: string) => error)
					});
					setOpenSnackBar(true);
					setShopifyCarrier(false);
				} else if (data?.errors) {
					setApiResponse({
						type: 'error',
						message: data?.errors
					});
					setOpenSnackBar(true);
					setShopifyCarrier(false);
				} else if (data?.message) {
					setApiResponse({ type: 'error', message: 'Shopify Store URL is Invalid' });
					setOpenSnackBar(true);
					setShopifyCarrier(false);
				}
				setLoading(false);
			}
		} catch (error: any) {
			setApiResponse({ type: 'error', message: 'Something went wrong' });
			setOpenSnackBar(true);
			setShopifyCarrier(false);
			setLoading(false);
		}
	};

	const handleAlertClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpenSnackBar(false);
	};

	return (
		<div
			style={{
				display: 'block',
				justifyContent: 'center',
				alignContent: 'center',
				marginBottom: '60px',
				maxWidth: 1200,
				marginLeft: 'auto',
				marginRight: 'auto'
			}}
		>
			<Grid container direction="column" alignContent="center" justifyContent="center" lineHeight={5}>
				<Grid item textAlign="center">
					<Typography variant="h5" mt={5} mb={5}>
						Get Live Rates On Your Shopify Store
					</Typography>
				</Grid>
				{shopify_carrier && !connected ? (
					<Grid item textAlign="center">
						<Paper elevation={3} sx={{ p: 2, margin: 'auto', maxWidth: 500, flexGrow: 1 }}>
							<Typography variant="h6">Shopify Connected</Typography>
							<Typography variant="body1" mt={5} mb={5}>
								Your Shopify store is already connected to TUNL. If you need to update your Shopify
								credentials, please contact support.
							</Typography>
						</Paper>
					</Grid>
				) : shopify_carrier ? (
					<Grid item textAlign="center">
						<Paper elevation={3} sx={{ p: 2, margin: 'auto', maxWidth: 500, flexGrow: 1 }}>
							<Typography variant="h6">Shopify Connected</Typography>
							<Typography variant="body1" mt={5} mb={5}>
								Your Shopify store has successfully connected to TUNL. If you need to update your
								Shopify credentials, please contact support.
							</Typography>
						</Paper>
					</Grid>
				) : (
					<Grid item xs={10} sm={10} md={10} lg={10} xl={10} textAlign="left">
						<Paper elevation={3} sx={{ p: 2, margin: 'auto', maxWidth: 600, flexGrow: 1 }}>
							<Box display="flex" alignItems="center">
								<img
									srcSet={`./img/shopify-logo.png`}
									src={`./img/shopify-logo.png`}
									alt='Shopify Logo'
									loading="lazy"
									style={{ marginLeft: '0px', width: '64px', height: '64px' }}
								/>
								<Typography variant="h6">Connect Shopify</Typography>
							</Box>
							

							<Typography variant="body1" mt={2} mb={2}>
								To connect your Shopify store follow{' '}
								<a href="https://help.tunl.to/en/article/shopify-live-rates-16gpes8/">
									{' '}
									these instructions
								</a>
								. to add the TUNL app and enter your credentials below.
							</Typography>
							<Grid container direction="column" justifyContent="flex-start">
								<Grid item xs={10} sm={10} md={10} lg={10} xl={10} mb={2}>
									<TextField
										label="Shopify Store Url"
										required
										fullWidth
										{...register('shopify_store_url', {
											required: 'Required'
										})}
										error={!!errors?.shopify_store_url}
										helperText={'Enter your Shopify store URL. Example: yourstore.myshopify.com'}
									/>
								</Grid>
								<Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
									<TextField
										label="Shopify API Access-Token"
										required
										fullWidth
										{...register('shopify_api_access_token', {
											required: 'Required'
										})}
										error={!!errors?.shopify_api_access_token}
										helperText={
											errors?.shopify_api_access_token
												? (errors.shopify_api_access_token.message as string)
												: null
										}
									/>
								</Grid>
								<Grid item mb={2}>
									<Box display="flex" alignItems="center" justifyContent="flex-start">
										<Typography sx={{ flex: 1 }}>
											{/* <LearnMore 
												href={'#'}
												label={'BETA: Enable to show rates with and without duties and taxes at checkout.'}
											/> */}
											BETA: Enable to show rates with and without duties and taxes at checkout.
										</Typography>
										<Switch
											//checked={showRatesWithDuties}
											color="primary"
											{...register('beta_tester')}
											onChange={handleToggleChange}
										/>
									</Box>
									{/* <LearnMore href={'#'}/> */}
								</Grid>

								{betaTesterToggle === true && (
									<>
										<Divider />
										<Grid item mt={2}>
											<Typography variant={'h6'} sx={{ flex: 1 }}>
												Please select which services to add to your checkout.
											</Typography>
											<FormGroup >
												<FormControlLabel 
													label="Economy Shipping With UPS Ground [Duties & Taxes Included]"
													control={
														<Checkbox 
															defaultChecked={betaTesterToggle} 
															sx={{color: errors.checkboxes ? 'red':'grey' }}
															{...register('ground')}
														/>
													} 
												/>
												<FormControlLabel 
													label="FedEx International Priority [Duties & Taxes Included]"
													control={
														<Checkbox 
															defaultChecked={betaTesterToggle} 
															sx={{color: errors.checkboxes ? 'red':'grey' }}
															{...register('fedex_international_priority_ddp')}
														/>
													} 
												/>
												<FormControlLabel 
													label="UPS Express Saver [Duties & Taxes Included]"
													control={
														<Checkbox 
															defaultChecked={betaTesterToggle}
															sx={{color: errors.checkboxes ? 'red':'grey' }}
															{...register('upssaver_ddp')}
														/>
													} 
												/>
												<FormControlLabel 
													label="FedEx International Priority [Duties & Taxes Not Included]"
													control={
														<Checkbox 
															defaultChecked={betaTesterToggle} 
															sx={{color: errors.checkboxes ? 'red':'grey' }}
															{...register('fedex_international_priority_dap')}
														/>
													} 
												/>
												<FormControlLabel 
													label="UPS Express Saver [Duties & Taxes Not Included]"
													control={
														<Checkbox 
															defaultChecked={betaTesterToggle}
															sx={{color: errors.checkboxes ? 'red':'grey' }}
															{...register('upssaver_dap')}
														/>
													} 
												/>
											</FormGroup>
											{errors.checkboxes && (
												<Typography color="error" variant="body2">
												{String(errors.checkboxes?.message)}
											  </Typography>
											)}
										</Grid>
									</>
								)} 
								
							</Grid>
					
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'flex-end',
									flexDirection: 'row',
									pt: 2
								}}
							>
								<Button onClick={handleSubmit} sx={{ mr: 1 }} color="primary">
									Connect
								</Button>
							</Box>
						</Paper>
					</Grid>
				)}
			</Grid>
			<Snackbar
				open={openSnackBar}
				autoHideDuration={3000}
				onClose={handleAlertClose}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			>
				<Alert onClose={handleAlertClose} severity={apiResponse.type} sx={{ width: '100%' }}>
					{apiResponse.message}
				</Alert>
			</Snackbar>
			<Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
		</div>
	);
}
