import { Grid, Typography } from "@mui/material";

export default function CostItem(props:any){
	const {costPrice, costLabel} = props;
	
	
	if (costPrice === 0 || costPrice === undefined || costPrice === null){
		return null;
	}
	return (
		<Grid item>
			<Typography variant="body1">
				{`${costLabel}: R${Number(costPrice).toFixed(2)}`}
			</Typography>
		</Grid>
	);
}