import { createSlice } from '@reduxjs/toolkit';

interface TrackerState {
	value: number;
	status: 'idle' | 'loading' | 'failed';
	detailStep: 0 | 1 | 2 | 3 | 4;
	details: unknown;
}

const initialState: TrackerState = {
	value: 0,
	status: 'idle',
	detailStep: 0,
	details: null
};

const trackerSlice = createSlice({
	name: 'tracker',
	initialState,
	reducers: {}
});

export default trackerSlice.reducer;
