import { Grid } from "@mui/material";

export default function ContentContainer(props: any) {
	return <div
				style={{
					display: 'block',
					justifyContent: 'center',
					alignContent: 'center',
					marginBottom: '60px',
					maxWidth: 1200,
					marginLeft: 'auto',
					marginRight: 'auto'
				}}
			> 
			<Grid container alignItems="center" direction="column">
				{ props.children }
			</Grid>
		</div>
}