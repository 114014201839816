import { Box, Paper, Typography } from '@mui/material';

export default function EconomyPickUpNotice() {
	return (
		<Box justifyContent={'flex-start'} alignContent={'flex-start'}>
			<Paper elevation={4} square={false} sx={{ mb: 2, p: 2 }}>
				<Box>
					<Typography variant="h5" mb={1} sx={{ fontWeight: 'bold', color: 'black' }}>
						Drop-Off
					</Typography>
					<Typography variant="body1">
						Please note that TUNL Economy is a drop-off only service. Please send your parcel to TUNL, UNIT
						52, 22 Cumberland Road, Paarden Eiland, Cape Town, 7405
					</Typography>
				</Box>
			</Paper>
		</Box>
	);
}
