import { Box, Grid, Paper, Typography, styled } from '@mui/material';
import React, { useMemo } from 'react';
import { calculateTotalRate } from '../BulkShipments';
import EconomyPickUpNotice from './EconomyPickUpNotice';
import ExpressPickUpNotice from './ExpressPickUpNotice';

export default function BulkShippingCost(props: any) {
	const { deliveryConfirmation } = props;
	const { selectedMockShipmentListTemp } = props;
	const { pickUp, setPickUp, timeSlot, setTimeSlot, setHasExpressShipments } = props;

	// Filter FedEx Shipments
	const fedexFilteredShipmentList = selectedMockShipmentListTemp.filter(
		(item: any) => item.shipment_data.service === 'FEDEX_INTERNATIONAL_PRIORITY'
	);

	const fedexHeaderTotal = fedexFilteredShipmentList.reduce((sum: number, item: any) => {
		return sum + Number(calculateTotalRate(item));
	}, 0);

	// Filter UPS Shipments
	const upsFilteredShipmentList = selectedMockShipmentListTemp.filter(
		(item: any) => item.shipment_data.service === 'UPSSaver'
	);

	const upsHeaderTotal = upsFilteredShipmentList.reduce((sum: number, item: any) => {
		return sum + Number(calculateTotalRate(item));
	}, 0);

	// Filter TUNL Shipments
	const tunlFilteredShipmentList = selectedMockShipmentListTemp.filter(
		(item: any) => item.shipment_data.service === 'Ground'
	);

	const tunlHeaderTotal = tunlFilteredShipmentList.reduce((sum: number, item: any) => {
		return sum + Number(calculateTotalRate(item));
	}, 0);

	setHasExpressShipments(fedexFilteredShipmentList?.length + upsFilteredShipmentList?.length > 0);
	const expressShipmentListSummary = useMemo(
		() => [
			{
				service: 'Fedex International Priority',
				shipments: fedexFilteredShipmentList,
				total: fedexHeaderTotal,
				image: 'FedEx.jpg'
			},
			{
				service: 'UPSSaver',
				shipments: upsFilteredShipmentList,
				total: upsHeaderTotal,
				image: 'UPS.jpg'
			},
			{
				service: 'TUNL',
				shipments: tunlFilteredShipmentList,
				total: tunlHeaderTotal,
				image: 'TUNL.png'
			}
		],
		[deliveryConfirmation]
	);

	const Img = styled('img')({
		display: 'flex',
		width: '100px',
		height: '75px',
		maxWidth: '100px',
		maxHeight: '75px',
		padding: '2px'
	});

	return (
		<>
			{fedexFilteredShipmentList?.length + upsFilteredShipmentList?.length > 0 && (
				<Box mb={2}>
					<ExpressPickUpNotice
						pickUp={pickUp}
						setPickUp={setPickUp}
						timeSlot={timeSlot}
						setTimeSlot={setTimeSlot}
					/>
				</Box>
			)}
			<Box>
				{expressShipmentListSummary.map(item =>
					item.total > 0 ? (
						<React.Fragment key={item.service}>
							{item.service === 'TUNL' && <EconomyPickUpNotice />}
							<Box position={'relative'}>
								<Paper elevation={4} sx={{ mb: 2, p: 2 }}>
									<Grid container direction="row" alignItems="center">
										<Grid item sm={2} lg={1.5} xl={1}>
											<Box position="relative">
												<Img src={`../img/${item.image}`} />
											</Box>
										</Grid>
										<Grid item sm={5}>
											<Box
												display="flex"
												alignItems="center"
												justifyContent="flex-start"
												height="100%"
											>
												<Typography
													variant="h5"
													mr={2}
													sx={{ fontWeight: 'bold', color: 'black' }}
												>
													{item?.service}
												</Typography>
											</Box>
										</Grid>
										<Grid item md={5} lg={5.5} xl={6}>
											<Box
												display="flex"
												alignItems="center"
												justifyContent="flex-end"
												height="100%"
											>
												<Typography variant="h5" sx={{ fontWeight: 'bold', color: 'black' }}>
													R {item.total?.toFixed(2)}
												</Typography>
											</Box>
										</Grid>
									</Grid>
								</Paper>
								{numberOfShipmentsPerService(item.shipments.length)}
							</Box>
						</React.Fragment>
					) : null
				)}

				<Box p={2}>
					<Grid container>
						<Grid item md={6}></Grid>
						<Grid item container md={6}>
							<Grid item md={6}>
								<Box display="flex" alignItems="center" justifyContent="flex-start" height="100%">
									<Typography variant="h5" ml={1} sx={{ fontWeight: 'bold', color: 'black' }}>
										TOTAL
									</Typography>
								</Box>
							</Grid>
							<Grid item md={6}>
								<Box display="flex" alignItems="center" justifyContent="flex-end" height="100%">
									<Typography variant="h5" sx={{ fontWeight: 'bold', color: 'black' }}>
										R{' '}
										{expressShipmentListSummary
											.reduce((sum, ship) => sum + ship.total, 0)
											?.toFixed(2)}
									</Typography>
								</Box>
							</Grid>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</>
	);
}

function numberOfShipmentsPerService(shipmentsCount: number) {
	return (
		<Box
			position="absolute"
			top={0}
			right={0}
			bgcolor="#007BC4"
			color="white"
			borderRadius="50%"
			width={24}
			height={24}
			display="flex"
			justifyContent="center"
			alignItems="center"
			fontSize="14px"
			mr={1}
			mt={1}
		>
			{shipmentsCount}
		</Box>
	);
}
