import {
	Modal,
	Box,
	Button,
	Grid,
	Typography,
	makeStyles,
	List,
	ListItem,
	ListItemText,
	ListItemIcon,
	useMediaQuery,
	useTheme
} from '@mui/material';
import { useState } from 'react';

export default function CustomsSampleModal(props: any) {
	const resetModalStyle = {
		position: 'absolute' as 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 'auto',
		height: 'auto',
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		pt: 4,
		px: 4,
		pb: 3
	};

	const resetModalStyleSm = {
		position: 'absolute' as 'absolute',
		top: '10%',
		left: '10%',
		right: '10%',
		bottom: 'auto',
		transform: 'auto',
		width: 'auto',
		height: 'auto',
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		pt: 4,
		px: 4,
		pb: 3
	};

	const { openModal, SetCloseModal,handleContentTypeChange } = props;
	//const [selectedContents, setSelectedContents] = useState(props.getValues('customs_info.contents_type'));
	const [modalOpen, setModalOpen] = useState(true);
	const handleOpen = () => setModalOpen(true);
	const theme = useTheme();
	const isXsSmMd = useMediaQuery(theme.breakpoints.up('md'));

	const onCancelClick = () => {
		handleContentTypeChange('merchandise');
		handleClose();
	
		// setSelectedContents('merchandise');
		// props.setValue('customs_info.contents_type', 'merchandise');
	}

	

	const handleClose = () => {
		if (modalOpen) {
			SetCloseModal(false);
			setModalOpen(false);
		}
	};

	return (
		<div>
			<Modal
				open={modalOpen}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={isXsSmMd ? resetModalStyle : resetModalStyleSm}>
					<Typography id="modal-modal-title" variant="h6" fontWeight={'bold'}>
						Important info regarding samples
					</Typography>
					<ul>
						<li>When booking a sample, you need to include "SAMPLE" in your product description.</li>
						<li>When booking a sample you need to label your packaging as "SAMPLE".</li>
						<li>
							When booking a sample, you need to have the word "SAMPLE" on your product, or make it clear
							it's not for resale.
						</li>
						<li>Samples may attract duties and taxes.</li>
					</ul>
					<Typography variant="h6">
						<a
							href="https://help.tunl.to/en/article/shipping-samples-5zpacb/"
							target="_blank"
							rel="noreferrer"
							style={{ color: '#50B4BD' }}
						>
							Learn More--{'>'}
						</a>
					</Typography>
					<Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, mt: 2 }}>
						<Button
							style={{ fontWeight: 'bold', justifyContent: 'flex-end', alignItems: 'flex-end' }}
							variant="text"		
							color="primary"
							onClick={onCancelClick}
						>
							CANCEL
						</Button>
						<Button
							style={{ fontWeight: 'bold', justifyContent: 'flex-end', alignItems: 'flex-end' }}
							variant="text"
							color="primary"
							onClick={handleClose}
						>
							CONFIRM
						</Button>
					</Box>
					
				</Box>
			</Modal>
		</div>
	);
}
