import { Box, Button } from '@mui/material';
import { MockShipment } from '../../../types/mockShipments';
import { BulkShipmentPageState } from './BulkShipments';
import BulkShipmentsTable from './components/BulkShipmentsTable';

interface BulkOrdersTableViewProps {
	setStepper: (step: BulkShipmentPageState) => void;
	setInProgress: (value: boolean | null) => void;
	mockShipmentListTemp: Array<MockShipment> | null;
	setSelectedMockShipmentListTemp: (value: any | null) => void;
	selectedMockShipmentListTemp: Array<MockShipment> | null;
}

export default function BulkOrdersTableView(props: BulkOrdersTableViewProps) {
	const {
		setStepper,
		setInProgress,
		selectedMockShipmentListTemp,
		mockShipmentListTemp,
		setSelectedMockShipmentListTemp
	} = props;

	const onBuyClickHandler = () => {
		setStepper(BulkShipmentPageState.BuyShipments);
	};

	const onCancelClickHandler = () => {
		setStepper(BulkShipmentPageState.ImportCSV);
		setInProgress(null);
	};

	return (
		<Box pt={2} pb={10} paddingX={4} sx={{ minHeight: '80vh' }}>
			<Box display="flex" justifyContent="flex-end">
				<Button
					sx={{ marginRight: '16px' }}
					variant="contained"
					onClick={onBuyClickHandler}
					disabled={selectedMockShipmentListTemp?.length === 0 ? true : false}
				>
					Buy {selectedMockShipmentListTemp?.length}
				</Button>
				<Button variant="contained" onClick={onCancelClickHandler}>
					{' '}
					Cancel
				</Button>
			</Box>
			<Box>
				<BulkShipmentsTable
					mockShipmentListTemp={mockShipmentListTemp}
					setSelectedMockShipmentListTemp={setSelectedMockShipmentListTemp}
				/>
			</Box>
		</Box>
	);
}
