import { Alert, Box, Button, Grid, Modal, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import { Download } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import LearnMore from '../../../Common/LearnMore';

export default function FlyerBagModal(props: any) {
	const [modalOpen, setModalOpen] = useState(true);
	const handleLeaveClose = () => setModalOpen(false);
	const { isOpen, SetCheckbox, openModal, SetCloseModal } = props;
	const theme = useTheme();
	const isXsSmMd = useMediaQuery(theme.breakpoints.up('md'));
	const resetModalStyle = {
		position: 'absolute' as 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 'auto',
		height: 'auto',
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		pt: 4,
		px: 4,
		pb: 3
	};

	const resetModalStyleSm = {
		position: 'absolute' as 'absolute',
		top: '10%',
		left: '10%',
		right: '10%',
		bottom: 'auto',
		transform: 'auto',
		width: 'auto',
		height: 'auto',
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		pt: 4,
		px: 4,
		pb: 3
	};

	const handleClickAway = () => {
		if (openModal) {
			SetCloseModal(false);
			setModalOpen(false);
		}
	};

	return (
		<Modal
			open={modalOpen}
			onClose={handleClickAway}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Grid container alignItems="center" direction="row">
				<Box
					sx={isXsSmMd ? resetModalStyle : resetModalStyleSm}
					style={{ border: 'none', boxShadow: '1px 1px solid gray' }}
				>
					<Grid container alignItems="center" direction="column">
						<Grid item width={'90%'} maxWidth={'768px'} overflow-y={'auto'}>
							<Grid item alignSelf="center">
								<Grid item direction="column">
									<Stack direction="row" alignItems="center" gap={1}>
										<ErrorIcon />
										<Typography variant="h6" fontWeight={'bold'}>
											Have you made your Flyer bag as small as possible?
										</Typography>
									</Stack>
									<ul>
										<li>
											Unlike domestic couriers, international shipping rates are based on the{' '}
											<u>
												<b>exact</b>
											</u>{' '}
											dimensions of your flyer bag, mailer bag or plastic pouch.
										</li>
										<li>Fold and tape your flyer bag efficiently to save money.</li>
										<li>
											Doing so means lower shipping costs and avoids additional charges for
											longer/loose edges.
										</li>
									</ul>
								</Grid>
								<Grid container direction={'row'} alignItems={'center'} justifyContent={'center'}>
									<Grid item>
										<LearnMore
											style={{ color: '#50B4BD' }}
											href="https://help.tunl.to/en/article/measuring-your-parcel-xz7zx3/#3-an-example-of-how-not-taping-your-parcel-can-affect-the-price-you-pay"
										/>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						<Grid container>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid
									container
									direction={'row'}
									mt={4}
									mb={1}
									spacing={2}
									xs={12}
									sm={12}
									md={12}
									lg={12}
									xl={12}
									justifyContent={'flex-end'}
									alignItems={'flex-end'}
								>
									<Grid item>
										<Button
											variant="contained"
											fullWidth
											color="primary"
											onClick={() => {
												SetCloseModal(false);
												setModalOpen(false);
											}}
										>
											Done
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Box>
			</Grid>
		</Modal>
	);
}
