import { createSlice } from '@reduxjs/toolkit';
export interface ShipmentState {
	merchant_details: {
		merchant_id: string;
		merchant_name: string;
		shipment_mode: string;
		merchant_city_locality: string;
		merchant_country: string;
		merchant_email: string;
		merchant_exporters_code: string;
		merchant_phone: string;
		merchant_state_province: string;
		merchant_street_1: string;
		merchant_street_2: string;
		merchant_user: string;
		merchant_vat_number: string;
		merchant_zip_postal: string;
	};
	address_details: {
		airtable_key: string;
		address_to_name: string;
		address_to_company: string;
		address_to_street_1: string;
		address_to_street_2: string;
		address_to_city_locality: string;
		address_to_state_province: string;
		address_to_zip_postal: string;
		address_to_country: string;
		address_to_phone: string;
		address_to_email: string;
		address_to_google_search: string;
		address_depot_name: string;
		address_depot_company: string;
		address_depot_street_1: string;
		address_depot_street_2: string;
		address_depot_city_locality: string;
		address_depot_state_province: string;
		address_depot_zip_postal: string;
		address_depot_country: string;
		address_depot_phone: string;
		address_depot_email: string;
	};
	parcel_details: {
		airtable_key: string;
		parcel_length_cm: number;
		parcel_width_cm: number;
		parcel_height_cm: number;
		parcel_weight_kg: number;
		shipment_reference: string;
	};
	line_items: [
		{
			line_item_description: string;
			line_item_hts_10_code: string;
			line_item_value: number;
			line_item_origin_country: string;
			line_item_pga_disclaimer: string;
			line_item_quantity: number;
			line_item_hs_6_code: string;
			line_item_sku: string;
			line_item_value_currency: string;
			line_value: number;
			save_line_item?: boolean;
		}
	];
}
const initialState: ShipmentState = {
	merchant_details: {
		merchant_id: '',
		merchant_name: '',
		shipment_mode: '',
		merchant_city_locality: '',
		merchant_country: '',
		merchant_email: '',
		merchant_exporters_code: '',
		merchant_phone: '',
		merchant_state_province: '',
		merchant_street_1: '',
		merchant_street_2: '',
		merchant_user: '',
		merchant_vat_number: '',
		merchant_zip_postal: ''
	},
	address_details: {
		airtable_key: '',
		address_to_name: '',
		address_to_company: '',
		address_to_street_1: '',
		address_to_street_2: '',
		address_to_city_locality: '',
		address_to_state_province: '',
		address_to_zip_postal: '',
		address_to_country: '',
		address_to_phone: '',
		address_to_email: '',
		address_to_google_search: '',
		address_depot_name: 'c/o World Container',
		address_depot_company: 'c/o World Container',
		address_depot_street_1: '475 DOUGHTY BLVD',
		address_depot_street_2: '',
		address_depot_city_locality: 'Inwood',
		address_depot_state_province: 'NY',
		address_depot_zip_postal: '11096',
		address_depot_country: 'US',
		address_depot_phone: '5163712228',
		address_depot_email: 'start@tunl.to'
	},
	parcel_details: {
		airtable_key: '',
		parcel_length_cm: 0,
		parcel_width_cm: 0,
		parcel_height_cm: 0,
		parcel_weight_kg: 0,
		shipment_reference: ''
	},
	line_items: [
		{
			line_item_description: '',
			line_item_hts_10_code: '',
			line_item_value: 0.0,
			line_item_origin_country: '',
			line_item_pga_disclaimer: '',
			line_item_quantity: 0,
			line_item_hs_6_code: '',
			line_item_sku: '',
			line_item_value_currency: '',
			line_value: 0.0,
			save_line_item: false
		}
	]
};
const shipmentSlice = createSlice({
	name: 'address',
	initialState,
	reducers: {
		setShipment: (state, action) => {
			switch (action.payload.type) {
				case 'ADDRESS':
					return {
						merchant_details: { ...action.payload.merchant_details },
						address_details: { ...action.payload.address_details },
						parcel_details: action.payload.shipment.parcel_details,
						line_items: action.payload.shipment.line_items
					};
				case 'PARCEL':
					return {
						merchant_details: action.payload.shipment.merchant_details,
						address_details: action.payload.shipment.address_details,
						parcel_details: { ...action.payload.parcel_details },
						line_items: action.payload.shipment.line_items
					};
				case 'CUSTOMS':
					return {
						merchant_details: action.payload.shipment.merchant_details,
						address_details: action.payload.shipment.address_details,
						parcel_details: action.payload.shipment.parcel_details,
						line_items: { ...action.payload.line_items }
					};
				case 'RESET':
					return {
						merchant_details: {},
						address_details: {},
						parcel_details: {},
						line_items: [{}]
					};
				default:
					break;
			}
			return action.payload;
		}
	}
});
export const { setShipment } = shipmentSlice.actions;
export default shipmentSlice.reducer;
