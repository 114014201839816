import { Box, CircularProgress, Grid, Paper, TextField, Typography, Switch } from '@mui/material';
import { useEffect, useState } from 'react';

import {
	CHECKOUT,
	CREATE_SHIPMENTS,
	PAPER,
	SHIPMENT_PROTECTION,
	TEXTFIELD,
	createElementId
} from '../../../../constants/id';

import { axiosConfig } from '../../../../constants/axios';
import { ShipmentData } from '../../../../types/shipment';
import LearnMore from '../../../Common/LearnMore';

interface ShipmentProtectionInputProps {
	shipment: ShipmentData;
	onProtectionChange: (addInsurance: boolean, protectionCost: number, insuranceValue: number) => void;
}

const getCurrency = (shipment: ShipmentData) => {
	return shipment.customs_info.customs_items[0].currency;
};

const getCustomsItemsTotal = (shipment: ShipmentData) => {
	return shipment.customs_info.customs_items.reduce((sum, item) => {
		return sum + item.value;
	}, 0);
};

const getBaseInsuranceValue = async (shipment: ShipmentData): Promise<number> => {
	const currency = getCurrency(shipment);
	const total = getCustomsItemsTotal(shipment);

	if (currency === 'ZAR') {
		return total;
	} else {
		const axiosInstance = axiosConfig();
		try {
			const response = await axiosInstance.post('/currency/convert', {
				source_currency: currency,
				target_currency: 'ZAR',
				amount: total
			});

			return response.data.data.converted_amount.toFixed(2);
		} catch (error) {
			// we want to set a base amount of 2000 incase the request fails.
			return 2000;
		}
	}
};

const ShipmentProtectionInput: React.FC<ShipmentProtectionInputProps> = ({ shipment, onProtectionChange }) => {
	const [addInsurance, setAddInsurance] = useState(true);
	const [insuranceValue, setInsuranceValue] = useState(shipment.insurance);
	const [loading, setLoading] = useState<boolean>(true);

	useEffect(() => {
		const setBaseAmount = async () => {
			setLoading(true);
			if (!insuranceValue) {
				const baseValue = await getBaseInsuranceValue(shipment);
				setInsuranceValue(baseValue);
			}
			setLoading(false);
			onProtectionChange(addInsurance, calculateInsuredValue() as number, insuranceValue);
		};

		setBaseAmount();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [shipment.customs_info]);

	useEffect(() => {
		onProtectionChange(addInsurance, calculateInsuredValue() as number, insuranceValue);
	});

	const validateInsuredValue = (value: any) => {
		return (value <= 0 || !value) && addInsurance;
	};

	const calculateInsuredValue = () => {
		if (!insuranceValue || insuranceValue < 2001) {
			return 50;
		}

		return (insuranceValue * 0.025).toFixed(2);
	};

	return (
		<Grid item xs={12} textAlign={'center'}>
			<Paper id={createElementId([CREATE_SHIPMENTS, CHECKOUT, PAPER, SHIPMENT_PROTECTION])} elevation={4}>
				<Box sx={{ minHeight: '300px' }}>
					<Grid container direction="row" alignItems="center" justifyContent={'center'}>
						<Grid item xs={12} sm={12} md={9} lg={8} xl={8}>
							<Grid container direction="row" p={2} alignItems="center" justifyContent={'center'}>
								<Grid item xs={12} mb={3}>
									<Typography variant="h5" mr={1} sx={{ fontWeight: 'bold', color: 'black' }}>
										<Switch
											checked={addInsurance}
											disabled={loading}
											onChange={(e: any) => {
												setAddInsurance(e.target.checked);
											}}
										/>
										Shipment Protection
									</Typography>
									<Typography variant="subtitle2">
										{addInsurance
											? 'Protect your shipment based on the total value of your customs items.'
											: 'Toggle to protect your shipment. This shipment will not be covered in the event of damage and loss'}
									</Typography>
								</Grid>
								{loading ? (
									<CircularProgress />
								) : (
									<>
										{addInsurance && (
											<Grid item xs={12}>
												<TextField
													id={createElementId([
														CREATE_SHIPMENTS,
														CHECKOUT,
														PAPER,
														SHIPMENT_PROTECTION,
														TEXTFIELD,
														'protection_value'
													])}
													fullWidth
													type="number"
													required
													error={validateInsuredValue(insuranceValue)}
													label="Protection Value (R)"
													disabled={!addInsurance}
													helperText={
														validateInsuredValue(insuranceValue)
															? 'Value must be greater than 0, toggle shipment protection to opt out.'
															: null
													}
													value={insuranceValue}
													inputProps={{ 'data-hj-allow': true }}
													onChange={(e: any) => {
														setInsuranceValue(e.target.value);
													}}
												/>
												<Box
													pt={2}
													sx={{
														display: 'flex',
														flexDirection: 'row',
														justifyContent: 'space-between',
														borderBottom: '1px solid #808080'
													}}
												>
													<Typography variant="body2" fontWeight={'bold'}>
														Shipping Protection Total Cost
													</Typography>
													<Typography variant="body2" fontWeight={'bold'}>
														R{calculateInsuredValue()}
													</Typography>
												</Box>
												<Typography variant="subtitle2" align={'left'} pt={'2'}>
													The protection cost is calculated at 2.5% of the value declared
													above. The minimum charge is R50.
													<LearnMore
														href="https://help.tunl.to/en/article/shipment-protection-xm4b8d/"
														inline={true}
														style={{ color: '#007bc4', 'padding-left': '5px' }}
													/>
												</Typography>
											</Grid>
										)}
									</>
								)}
							</Grid>
						</Grid>
					</Grid>
				</Box>
			</Paper>
		</Grid>
	);
};

export default ShipmentProtectionInput;
