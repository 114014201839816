import { Button } from "@mui/material";


export default function SnapScanPayNow(props:any){
	const {shipment,shipmentProtection, landedCosts, deliveryConfirmationCost} = props;

	return (
		<Button
			sx={{
				backgroundColor: '#027b2c',
				':hover': {
					backgroundColor: '#019636'
				},
				whiteSpace: 'nowrap',
				
			}}
			color="primary"
			variant="contained"
			size="small"
			onClick={() => {
				const total = (
					parseFloat(shipmentProtection.toFixed(2)) + parseFloat(shipment.rate_charged) + (isNaN(landedCosts) ? 0 : landedCosts) + deliveryConfirmationCost
				).toFixed(2);

				const snapScanTotal = Math.round(Number(total) * 100);

				window.open(
					`https://pos.snapscan.io/qr/ZtzVHGvO?id=${shipment.shipment_id}&amount=${snapScanTotal}`,
					'_blank',
					'noopener,noreferrer'
				);
			}}
		>
			Pay Now
		</Button>
	);
}