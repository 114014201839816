import { Grid, Link, styled, Typography, useMediaQuery, useTheme } from '@mui/material';

const Img = styled('img')({
	display: 'block',
	maxWidth: '160px',
	maxHeight: '160px'
});

export default function Footer() {
	const theme = useTheme();
	const xs = useMediaQuery(theme.breakpoints.down('xs'));
	const sm = useMediaQuery(theme.breakpoints.down('sm'));
	const md = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<div
			style={{
				color: '#ffffff',
				position: 'fixed',
				bottom: 0,
				background: '#030303',
				width: '100%',
				height: '8vh',
				paddingTop: '6px',
				paddingBottom: '6px'
			}}
		>
			<Grid
				container
				alignItems="center"
				justifyContent="space-between"
				direction={xs || sm || md ? 'column' : 'row'}
			>
				<Grid item xs={12} sm={12} md={6} lg={6} xl={6} padding={xs || sm || md ? '0px' : '15px'}>
					<Img src="../img/tunl-logo-cream.png" />
				</Grid>
				<Grid item xs={12} sm={12} md={6} lg={6} xl={6} padding={xs || sm || md ? '0px' : '15px'}>
					<Link href="mailto:support@tunl.to" color="inherit" underline="always">
						<Typography textAlign="right">support@tunl.to</Typography>
					</Link>
				</Grid>
			</Grid>
		</div>
	);
}
