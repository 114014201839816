import { CloudUpload } from '@mui/icons-material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { Box, Button, CircularProgress, Container, Grid, Paper, Typography, styled } from '@mui/material';
import { BulkShipmentPageState } from './BulkShipments';

const Input = styled('input')({
	display: 'none'
});

const styleContainer = {
	height: '20vh',
	bgcolor: '#f0f0f0',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center'
};

interface UploadCSVProps {
	fileInputRef: React.RefObject<HTMLInputElement>;
	onFileChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
	acceptableCSVFileTypes: string;
	onUploadClickHandler: () => void;
	inProgress: boolean | null;
	setInProgress: (input: boolean | null) => void;
	importCSVErrors: boolean;
	setImportCSVErrors: (input: boolean) => void;
	importCSVErrorMsg: string | null;
	setStepper: (step: BulkShipmentPageState) => void;
}

export default function UploadCSV(props: UploadCSVProps) {
	const {
		fileInputRef,
		onFileChangeHandler,
		acceptableCSVFileTypes,
		onUploadClickHandler,
		inProgress,
		setInProgress,
		importCSVErrors,
		setImportCSVErrors,
		importCSVErrorMsg,
		setStepper
	} = props;

	return (
		<Container>
			<Box pt={8} sx={{ minHeight: '100vh' }}>
				<Paper elevation={4}>
					<Container>
						{inProgress === null
							? initUploadCSVStateView()
							: inProgress === true
							? loadingUploadCSVStateView()
							: importCSVErrors === false
							? completeUploadCSVSuccessStateView()
							: completeUploadCSVFailedStateView()}
					</Container>
				</Paper>
			</Box>
		</Container>
	);

	function initUploadCSVStateView() {
		return (
			<Box>
				<Grid container>
					<Grid item xs={12}>
						<Box sx={{ textAlign: 'center' }}>
							<Typography m={2} variant="h4">
								Import CSV File
							</Typography>
							<Typography>
								Click “Upload CSV” to bulk purchase shipments. After uploading, you’ll be able to review
								all shipment details and costs before completing your purchase.
							</Typography>
						</Box>
					</Grid>
					<Grid item xs={12} my={2}>
						<Container disableGutters={true} sx={styleContainer}>
							<label
								htmlFor="upload-button"
								style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
							>
								<Input
									id="upload-button"
									type="file"
									ref={fileInputRef}
									onChange={onFileChangeHandler}
									accept={acceptableCSVFileTypes}
								/>
								<CloudUpload sx={{ fontSize: 60, color: 'lightgray' }} />
								<Button variant="contained" onClick={onUploadClickHandler} sx={{ m: 2 }}>
									Upload CSV
								</Button>
							</label>
						</Container>
					</Grid>
				</Grid>
			</Box>
		);
	}

	function loadingUploadCSVStateView() {
		return (
			<Box>
				<Grid container>
					<Grid item xs={12}>
						<Box sx={{ textAlign: 'center' }}>
							<Typography m={2} variant="h4">
								Importing CSV File
							</Typography>
							<Typography>
								This should take a few minutes. Please do not navigate away from this page
							</Typography>
						</Box>
					</Grid>
					<Grid item xs={12} my={2}>
						<Container disableGutters={true} sx={styleContainer}>
							<CircularProgress />
						</Container>
					</Grid>
				</Grid>
			</Box>
		);
	}

	function completeUploadCSVSuccessStateView() {
		const onGotoOrdersClickHandler = () => {
			setStepper(BulkShipmentPageState.ViewOrders);
		};

		return (
			<Box>
				<Grid container>
					<Grid item xs={12}>
						<Box sx={{ textAlign: 'center' }}>
							<Typography m={2} variant="h4">
								CSV Import Successful
							</Typography>
							<Typography>
								Your CSV file was imported successfully. Click “Go to Orders” to review and finalize
								your shipments before purchase.
							</Typography>
						</Box>
					</Grid>
					<Grid item xs={12} mb={2}>
						<Container disableGutters={true} sx={styleContainer}>
							<CheckCircleOutlinedIcon sx={{ fontSize: 60, color: 'lightgray' }} />
							<Button variant="contained" sx={{ mt: 2 }} onClick={onGotoOrdersClickHandler}>
								Go To Orders
							</Button>
						</Container>
					</Grid>
				</Grid>
			</Box>
		);
	}

	function completeUploadCSVFailedStateView() {
		const onBackClickHandler = () => {
			setStepper(BulkShipmentPageState.ImportCSV);
			setInProgress(null);
			setImportCSVErrors(false);
		};

		return (
			<Box>
				<Grid container>
					<Grid item xs={12}>
						<Box sx={{ textAlign: 'center' }}>
							<Typography m={2} variant="h4">
								CSV Import Failed
							</Typography>
							<Typography>
								There was an issue with your CSV file. Please check the file for errors and try again.
							</Typography>
							<Typography color={'red'}>{importCSVErrorMsg}</Typography>
						</Box>
					</Grid>
					<Grid item xs={12} mb={2}>
						<Container disableGutters={true} sx={styleContainer}>
							<CancelOutlinedIcon sx={{ fontSize: 60, color: 'lightgray' }} />
							<Button variant="contained" sx={{ mt: 2 }} onClick={onBackClickHandler}>
								Back
							</Button>
						</Container>
					</Grid>
				</Grid>
			</Box>
		);
	}
}
