import { Alert, Box, Grid, Snackbar, Typography } from '@mui/material';
import Papa from 'papaparse';
import { useRef, useState } from 'react';
import { axiosConfig } from '../../../constants/axios';
import { MerchantRecord } from '../../../types/merchant';
import {
	LandedCostRequest,
	MockShipment,
	ShippingRateLandedCostRequest,
	ShippingRateRequest
} from '../../../types/mockShipments';
import { CustomsItem } from '../../../types/shipment';
import { SnackAlert } from '../../../types/util';
import { TunlValidator } from '../../../util/tunlValidator';
import BulkBuyLabel from './BulkBuyLabels';
import BulkOrdersTableView from './BulkOrdersTableView';
import UploadCSV from './UploadCSV';

// Files types can be added on
const acceptableCSVFileTypes = '.csv';

export enum BulkShipmentPageState {
	ImportCSV,
	ViewOrders,
	BuyShipments
}

export const calculateInsuredValue = (insuredValue: number) => {
	let insuranceCost: number;
	if (!insuredValue) {
		insuranceCost = 0;
	} else if (insuredValue <= 2000) {
		insuranceCost = 50;
	} else {
		insuranceCost = insuredValue * 0.025;
	}
	return insuranceCost.toFixed(2);
};

export const calculateTotalRate = (rowItem: any) => {
	const shippingRate = Number(rowItem?.selected_rate?.rate) || 0;
	let landedCostRate = 0;
	const landedCost = rowItem?.landed_cost;
	const insurance = calculateInsuredValue(rowItem.shipment_data?.insurance);
	const deliveryCost = rowItem.shipment_data?.signature ? 50 : 0;

	if (rowItem?.service_type === 'DAP' || rowItem.selected_rate?.service === 'Ground') {
		return (Number(shippingRate) + Number(insurance) + deliveryCost).toFixed(2);
	}

	if (landedCost && landedCost.amountSubtotals) {
		const { amountSubtotals } = landedCost;
		let landedCostTotal = amountSubtotals.duties + amountSubtotals.fees + amountSubtotals.taxes;
		const { exchangeRate } = landedCost;
		if (exchangeRate) {
			if (exchangeRate.sourceCurrencyCode !== exchangeRate.targetCurrencyCode) {
				landedCostTotal = parseFloat((landedCostTotal * exchangeRate.rate).toFixed(2));
			}
		}
		landedCostRate = Number(landedCostTotal);
	}
	return (Number(shippingRate) + Number(landedCostRate) + Number(insurance) + deliveryCost).toFixed(2);
};

export default function BulkShipment() {
	const fileInputRef = useRef<HTMLInputElement | null>(null);
	const merchant: MerchantRecord = JSON.parse(sessionStorage.getItem('merchant') as string);
	const axiosInstance = axiosConfig();
	const [inProgress, setInProgress] = useState<boolean | null>(null);
	const [uploadComplete, setUploadComplete] = useState(false);
	const [importCSVErrors, setImportCSVErrors] = useState<boolean>(false);
	const [importCSVErrorsMsg, setImportCSVErrorsMsg] = useState<string | null>('');
	const [stepper, setStepper] = useState<BulkShipmentPageState>(BulkShipmentPageState.ImportCSV);
	const [mockShipmentListTemp, setMockShipmentListTemp] = useState<any | null>(null);
	const [selectedMockShipmentListTemp, setSelectedMockShipmentListTemp] = useState<Array<MockShipment> | null>(null);
	const [snackAlert, setSnackAlert] = useState<SnackAlert>({ type: 'success', message: '' });
	const [openSnackBar, setOpenSnackBar] = useState(false);
	const [hideDuration, setHideDuration] = useState<number | null>(3000);

	const onFileChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		setUploadComplete(false);
		const file = event.target.files?.[0];
		Papa.parse(file!, {
			complete: async function (results: any) {
				setInProgress(true);
				setImportCSVErrorsMsg(TunlValidator.csvHeaders(results.data[0]));
				const isValidHeaders = importCSVErrorsMsg === '' ? true : false;
				if (!isValidHeaders) {
					setTimeout(() => {
						setInProgress(false);
						setImportCSVErrors(!isValidHeaders);
					}, 3000);
				} else {
					const shipmentsListData: Array<MockShipment> = parseCSVToMockShipmentData(results.data);
					const expressRatesNoShipmentDataList = shipmentsListData.map(dataItem =>
						parseShipmentDataToGetExpressRatesLandedCostNoShipment(dataItem, merchant)
					);

					try {
						const expressRatesLandedCostRes = await axiosInstance.post(
							'/getExpressRatesLandedCostNoShipment',
							expressRatesNoShipmentDataList
						);
						const tableData = shipmentsListData.map((item, index) => {
							const rateData = {
								shipment_data: { ...item },
								...expressRatesLandedCostRes.data.data[index]
							};
							const totalCost = calculateTotalRate(rateData);
							rateData.totalCost = totalCost;
							rateData.shipment_data.rate = rateData.selected_rate?.rate;
							return rateData;
						});
						setMockShipmentListTemp(tableData);
						setUploadComplete(true);
						setInProgress(false);
						setImportCSVErrors(!isValidHeaders);
					} catch (error) {
						console.error('Error in requests:', error);
						setHideDuration(null);
						setSnackAlert({
							type: 'error',
							message: 'Something went wrong. Cannot calculate shipping rates at the moment.'
						});
						setOpenSnackBar(true);
						setInProgress(null);
					}
				}
			}
		});
	};

	const handleAlertClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenSnackBar(false);
	};

	const onUploadClickHandler = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};

	const setShipmentRateRequest = (dataItem: MockShipment, merchant: MerchantRecord): ShippingRateRequest => {
		return {
			from_address: {
				address_from_name: merchant.merchant_name,
				address_from_company: merchant.merchant_name,
				address_from_street_1: merchant.merchant_street_1,
				address_from_street_2: merchant.merchant_street_2,
				address_from_city_locality: merchant.merchant_city_locality,
				address_from_state_province: merchant.merchant_state_province,
				address_from_zip_postal: merchant.merchant_zip_postal,
				address_from_country: merchant.merchant_country,
				address_from_phone: merchant.merchant_phone,
				address_from_email: merchant.merchant_email
			},
			to_address: {
				address_to_name: dataItem.address_to_name,
				address_to_company: dataItem.address_to_company,
				address_to_street_1: dataItem.address_to_street_1,
				address_to_street_2: dataItem.address_to_street_2,
				address_to_city_locality: dataItem.address_to_city_locality,
				address_to_state_province: dataItem.address_to_state_province,
				address_to_zip_postal: TunlValidator.validatePostalCode(
					dataItem.address_to_country,
					dataItem.address_to_zip_postal
				),
				address_to_country: dataItem.address_to_country,
				address_to_phone: dataItem.address_to_phone,
				address_to_email: dataItem.address_to_email
			},
			parcel_details: {
				parcel_height_cm: Number(dataItem.parcel_height_cm ?? 0),
				parcel_length_cm: Number(dataItem.parcel_length_cm ?? 0),
				parcel_weight_kg: Number(dataItem.parcel_weight_kg ?? 0),
				parcel_width_cm: Number(dataItem.parcel_width_cm ?? 0)
			}
		};
	};

	const setLandedCostsRequest = (dataItem: MockShipment): LandedCostRequest => {
		const items = dataItem.customs_info.customs_items.map((item, index) => {
			return {
				id: `item ${index + 1}`,
				amount: item.value,
				country_of_origin: 'ZA',
				hs_code: item.hs_tariff_number,
				quantity: 1 //item.quantity
			};
		});

		return {
			currency: dataItem.customs_info.customs_items[0].currency,
			discounts: [
				{
					id: 'sale124',
					amount: 0.0,
					detail: null
				}
			],
			items: items,
			incoterm: dataItem.shipping_type ? 'delivery_duty_paid' : 'delivery_duty_unpaid',
			ship_from_country: 'ZA',
			ship_to: {
				city: dataItem.address_to_city_locality,
				country: dataItem.address_to_country,
				postal_code: dataItem.address_to_zip_postal,
				state: dataItem.address_to_state_province
			},
			shipping: {
				amount: 0,
				service_level:
					dataItem.service === 'FEDEX_INTERNATIONAL_PRIORITY'
						? 'fedex_international_priority'
						: dataItem.service === 'UPS_SAVER'
						? 'ups_express_saver'
						: ''
			},
			tariff_rate: 'maximum'
		};
	};

	const parseShipmentDataToGetExpressRatesLandedCostNoShipment = (
		dataItem: MockShipment,
		merchant: MerchantRecord
	): ShippingRateLandedCostRequest => {
		const expressRatesLandedCostNoShipmentData: ShippingRateLandedCostRequest = {
			shipment: setShipmentRateRequest(dataItem, merchant),
			landed_costs: setLandedCostsRequest(dataItem),
			service: dataItem.service,
			shipping_type: dataItem.shipping_type
		};
		return expressRatesLandedCostNoShipmentData;
	};

	const headersToSnakeCase = (header: string): string => {
		return header
			.replace(/\s/g, '_') // Replace spaces with underscores
			.replace(/[()]/g, '') // Remove parentheses
			.replace(/[^\w_]/g, '') // Remove any non-word characters except underscores
			.toLowerCase(); // Convert to lowercase
	};

	const parseCSVToMockShipmentData = (data: string[][]): MockShipment[] => {
		const headers = data[0]; // First row contains headers

		const date = new Date();
		const options: Intl.DateTimeFormatOptions = {
			year: 'numeric',
			month: 'long',
			day: 'numeric'
		};

		const shipmentsData: MockShipment[] = data.slice(1).map(row => {
			const customsItems: Array<CustomsItem> = [];

			for (let i = 22; i < row.length; i += 6) {
				//TODO: build validation layer over here
				if (row[i] !== '') {
					customsItems.push({
						description: row[i] || '',
						hs_tariff_number: row[i + 1] || '',
						quantity: parseInt(row[i + 2]) || 0,
						weight: parseFloat(row[i + 3]) || 0,
						value: parseFloat(row[i + 4]) || 0,
						currency: row[i + 5] as CustomsItem['currency'],
						origin_country: 'ZA' // Assume origin country is consistent with address
					});
				}
			}
			// Map row data to MockShipment object
			return {
				order_number: row[0],
				address_to_name: row[1],
				address_to_company: row[2],
				address_to_street_1: row[3],
				address_to_street_2: row[4],
				address_to_city_locality: row[5],
				address_to_state_province: row[6],
				address_to_zip_postal: row[7],
				address_to_country: row[8],
				address_to_phone: row[9],
				address_to_email: row[10],
				parcel_length_cm: row[11],
				parcel_width_cm: row[12],
				parcel_height_cm: row[13],
				parcel_weight_kg: row[14],
				service: mapUserServiceToRatingService(row[15]),
				shipping_type: row[16],
				to_return: merchant.merchant_settings.to_return,
				insurance: +row[17],
				invoice: row[18],
				product_reference: row[19],
				content_type: row[20],
				signature: JSON.parse(row[21]?.toLocaleLowerCase()),
				customs_info: {
					contents_type: row[22], // Placeholder
					customs_items: customsItems
					//non_delivery_option: 'Return to sender' // Placeholder
				},
				date_created: date.toLocaleDateString(undefined, options)
			};
		}) as MockShipment[];
		return shipmentsData;
	};

	function mapUserServiceToRatingService(userServiceInput: string): string {
		const rateService =
			userServiceInput === 'FEDEX_INTERNATIONAL_PRIORITY'
				? 'FEDEX_INTERNATIONAL_PRIORITY'
				: userServiceInput === 'UPS_SAVER'
				? 'UPSSaver'
				: 'TUNL_ECONOMY'
				? 'Ground'
				: '';
		return rateService;
	}

	function renderPageContent(stepper: BulkShipmentPageState) {
		switch (stepper) {
			case BulkShipmentPageState.ViewOrders:
				return (
					<BulkOrdersTableView
						setStepper={setStepper}
						setInProgress={setInProgress}
						mockShipmentListTemp={mockShipmentListTemp}
						setSelectedMockShipmentListTemp={setSelectedMockShipmentListTemp}
						selectedMockShipmentListTemp={selectedMockShipmentListTemp}
					/>
				);

			case BulkShipmentPageState.BuyShipments:
				return (
					<BulkBuyLabel
						selectedMockShipmentListTemp={selectedMockShipmentListTemp}
						setSelectedMockShipmentListTemp={setSelectedMockShipmentListTemp}
						setStepper={setStepper}
					/>
				);

			default:
				return (
					<UploadCSV
						fileInputRef={fileInputRef}
						onFileChangeHandler={onFileChangeHandler}
						acceptableCSVFileTypes={acceptableCSVFileTypes}
						onUploadClickHandler={onUploadClickHandler}
						inProgress={inProgress}
						setInProgress={setInProgress}
						importCSVErrors={importCSVErrors}
						setImportCSVErrors={setImportCSVErrors}
						importCSVErrorMsg={importCSVErrorsMsg ?? ''}
						setStepper={setStepper}
					/>
				);
		}
	}

	return (
		<Box>
			<Grid container direction="column" alignItems="left">
				<Grid item md={12}>
					<Typography variant="h4" m={2}>
						Bulk Shipments
					</Typography>
				</Grid>
				<Grid item md={12}>
					{renderPageContent(stepper)}
				</Grid>
			</Grid>
			<Snackbar
				open={openSnackBar}
				autoHideDuration={hideDuration}
				onClose={handleAlertClose}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			>
				<Alert onClose={handleAlertClose} severity={snackAlert.type} sx={{ width: '100%' }}>
					{snackAlert.message}
				</Alert>
			</Snackbar>
		</Box>
	);
}
