export const invalidChapters: string[] = ['01', '02', '03', '04', '06', '07', '36', '93', '98', '99'];

export const warningChapters: string[] = [
	'05',
	'08',
	'09',
	'10',
	'11',
	'12',
	'13',
	'14',
	'15',
	'16',
	'17',
	'18',
	'19',
	'20',
	'21',
	'22',
	'25',
	'26',
	'27',
	'28',
	'29',
	'30',
	'31',
	'32',
	'33',
	'34',
	'35',
	'38',
	'39',
	'40',
	'41',
	'42',
	'43',
	'44',
	'45',
	'46',
	'47',
	'65',
	'69',
	'70',
	'71',
	'72',
	'73',
	'74',
	'76',
	'82',
	'84',
	'85',
	'86',
	'87',
	'88',
	'89',
	'90'
];
