import {
	AccountCircle,
	Add,
	FitnessCenter,
	Flight,
	Handyman,
	Home,
	HouseSharp,
	Inventory,
	Logout,
	MenuBook,
	Storefront,
	SupervisedUserCircle
} from '@mui/icons-material';
import PinDropIcon from '@mui/icons-material/PinDrop';

import {
	Link,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	SwipeableDrawer,
	Toolbar
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import { toLower } from 'lodash';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { BUTTON, createElementId, LIST_ITEM, SIDEMENU } from '../../constants/id';
import TEXT from '../../constants/text';

export const drawerWidth = 240;

interface SidebarProps {
	open: boolean;
	onOpen: () => void;
	onClose: () => void;
	isXsSmMd: boolean;
	signUserOut: () => void;
}

export const Sidebar: React.FC<SidebarProps> = ({ open, onOpen, onClose, isXsSmMd, signUserOut }) => {
	const navigate = useNavigate();
	const user = JSON.parse(sessionStorage.getItem('user') as string);
	const postHogBatchShipmentFlagEnabled = useFeatureFlagEnabled('batch-shipments-ft');
	const batchShipmentFlagEnabled =
		process.env.REACT_APP_ENVIRONMENT === 'prod' ? postHogBatchShipmentFlagEnabled : true;

	const location = useLocation();

	const showAdminLinks = () => {
		return user && user.admin_access;
	};

	const menuItemClick = (type: string, path: string) => {
		if (type === 'link') {
			navigate(path);
		} else if (type === 'external') {
			window.open(path, '_blank');
		} else if (type === 'logout') {
			signUserOut();
		} else {
			throw new Error('Menu item has no type');
		}
	};

	const menuItems = [
		{ text: TEXT.DASHBOARD, icon: <Home />, path: '/', admin: false, type: 'link' },
		{ text: TEXT.CUSTOMS_DASHBOARD, icon: <HouseSharp />, path: '/customs/dashboard', admin: true, type: 'link' },
		{ text: TEXT.VIEW_SHIPMENTS, icon: <Flight />, path: '/customs/shipments', admin: true, type: 'link' },
		{
			text: TEXT.CREATE_NEW_BOOKING,
			icon: <MenuBook />,
			path: '/customs/booking/create',
			admin: true,
			type: 'link'
		},
		{ text: TEXT.CREATE_SHIPMENT, icon: <Add />, path: '/shipment', admin: false, type: 'link' },
		{
			text: TEXT.BULK_SHIPMENTS,
			icon: <FitnessCenter />,
			path: '/shipment/bulk',
			admin: false,
			type: 'link'
		},
		{ text: TEXT.PRODUCT_LIBRARY, icon: <Inventory />, path: '/products', admin: false, type: 'link' },
		{ text: TEXT.MERCHANT_PROFILE, icon: <AccountCircle />, path: '/profile', admin: false, type: 'link' },
		{ text: TEXT.MANAGE_USERS, icon: <SupervisedUserCircle />, path: '/users', admin: false, type: 'link' },
		{ text: TEXT.SALES_CHANNELS, icon: <Storefront />, path: '/live-rates', admin: false, type: 'link' },
		{ text: TEXT.TRACKER, icon: <PinDropIcon />, path: '/tracker', admin: false, type: 'link' },
		{
			text: TEXT.TOOLS,
			icon: <Handyman />,
			path: 'https://www.tunl.to/tools?signedin=true',
			admin: false,
			type: 'external'
		},
		{ text: TEXT.LOGOUT, icon: <Logout />, path: '', admin: false, type: 'logout' }
	];

	return (
		<SwipeableDrawer
			variant={isXsSmMd ? 'persistent' : 'temporary'}
			open={open}
			onOpen={onOpen}
			onClose={onClose}
			sx={{
				width: drawerWidth,
				overflowY: 'auto'
			}}
		>
			<Toolbar />
			<List sx={{ width: drawerWidth }}>
				{menuItems
					.filter(item => (showAdminLinks() ? true : !item.admin))
					.filter(item => (batchShipmentFlagEnabled ? true : !(item.text === TEXT.BULK_SHIPMENTS)))
					.map((item, index) => (
						<ListItem
							id={createElementId([SIDEMENU, LIST_ITEM, toLower(item.text).replace(' ', '-')])}
							key={index}
							disablePadding
						>
							<ListItemButton
								id={createElementId([SIDEMENU, BUTTON, toLower(item.text)])}
								component={Link}
								onClick={event => menuItemClick(item.type, item.path)}
								selected={location.pathname === item.path}
							>
								<ListItemIcon sx={{ 'min-width': '35px' }}>{item.icon}</ListItemIcon>
								<ListItemText primary={item.text} />
							</ListItemButton>
						</ListItem>
					))}
			</List>
		</SwipeableDrawer>
	);
};
