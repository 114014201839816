import { Alert, Backdrop, Button, CircularProgress, Grid, Snackbar, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import CustomsTable from '../../components/Customs/CustomsTable';
import { axiosConfig } from '../../constants/axios';
import { Shipment } from '../../types/shipment';
import { SnackAlert } from '../../types/util';

const INITIAL_SNACK_ALERT: SnackAlert = { type: 'success', message: '' };

const resetModalStyle = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	pt: 2,
	px: 4,
	pb: 2
};

export default function CustomsPushtrackers(props: any) {
	const user = JSON.parse(sessionStorage.getItem('user') as string);
	const location = useLocation();
	const [loading, setLoading] = useState(true);
	const [openSnackBar, setOpenSnackBar] = useState(false);
	const [bookingId, setBookingId] = useState(location.state as string);
	const [open, setOpen] = useState(false);
	const [dialogOpen, setDialogOpen] = useState(false);
	const [bulkModalOpen, setBulkModalOpen] = useState(false);
	const [openRow, setOpenRow] = useState(false);
	const [listValue, setListValue] = useState('');
	const [listMessage, setListMessage] = useState('');
	const [customListValue, setCustomListValue] = useState('');
	const [customMessage, setCustomMessage] = useState('');
	const [customLocation, setCustomLocation] = useState('');
	const [modalOpen, setModalOpen] = useState(false);
	const [dialogBox, setDialogBox] = useState(false);
	const [bulkDialogBox, setBulkDialogBox] = useState(false);
	const handleLeaveClose = () => setModalOpen(false);
	const bulkLeaveClose = () => setBulkModalOpen(false);
	const [selectedShipment, setSelectedShipment] = useState<Shipment>();
	const [snackAlert, setSnackAlert] = useState<SnackAlert>(INITIAL_SNACK_ALERT);
	const [shipments, setShipments] = useState<Shipment[]>([]);
	const enRoute = 'en_route_to_usa';
	const enRouteMessage = 'Shipment departed on flight to the USA. Expected to arrive in the next 2-3 days.';
	const arrived = 'parcel_arrived';
	const arrivedMessage =
		'Parcel arrived in the USA and customs cleared. Parcel should start tracking with final-mile carrier in the next 2 days.';
	const cleared = 'parcel_cleared_for_export';
	const clearedMessage =
		'Parcel released from South African customs. Shipment loaded for departure from South Africa this weekend.';
	const scanned = 'scanned_into_booking';
	const scannedMessage = 'Shipment ready for customs clearance out of South Africa.';
	const [updated, sentUpdate] = useState(false);

	const handleAlertClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenSnackBar(false);
	};
	const axiosInstance = axiosConfig();

	const handleBulkUpdates = async (message: string, description: string) => {
		setLoading(true);

		const manualTrackerInfo = {
			booking_id: bookingId,
			event_status: selectedShipment?.event_status,
			event_status_detail: message,
			manual_tracker: {
				event_status_detail: description,
				date_created: datePushedTime,
				city:
					message === enRoute
						? 'Cape Town'
						: message === arrived
						? 'New York'
						: message === cleared
						? 'Cape Town'
						: message === scanned
						? 'Cape Town'
						: customLocation,
				state:
					message === enRoute
						? 'WC'
						: message === arrived
						? 'NY'
						: message === cleared
						? 'WC'
						: message === scanned
						? 'WC'
						: '',
				country:
					message === enRoute
						? 'ZA'
						: message === arrived
						? 'US'
						: message === cleared
						? 'ZA'
						: message === scanned
						? 'ZA'
						: '',
				zip:
					message === enRoute
						? '7925'
						: message === arrived
						? '11434'
						: message === cleared
						? '7925'
						: message === scanned
						? '7925'
						: ''
			}
		};
		try {
			const response = await axiosInstance.post('/bulkUpdateTracker', manualTrackerInfo);
			if (response.status === 200) {
				sentUpdate(true);
				setSnackAlert({ type: 'success', message: 'Updated All Trackers' });
				setOpenSnackBar(true);
			}
			if (response.status === 504) {
				throw response.data;
			}
		} catch (error: any) {
			console.error('Error occurred during API request:', error.message);
			setSnackAlert({ type: 'error', message: error.message });
			setOpenSnackBar(true);
			setLoading(false);
		}
	};

	useEffect(() => {
		if (!user.admin_access) return;
		fetchShipments();
	}, [bookingId, updated]);

	const fetchShipments = async () => {
		setLoading(true);
		try {
			const response = await axiosInstance.post(`/shipment/getShipmentsBookingID`, {
				booking_id: bookingId
			});
			const extractedShipments = response.data.data.map((shipment: any) => ({
				...shipment
			}));
			setShipments(extractedShipments);
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
			sentUpdate(false);
		}
	};

	const handleRowClick = (rowData: any) => {
		setSelectedShipment(rowData);
	};

	const datePushedTime = new Date().toISOString();

	return (
		<>
			{!user.admin_access ? (
				<Navigate to="/" />
			) : (
				<>
					{!loading ? (
						<>
							<Grid container alignItems="center" direction="column">
								<Typography variant="h4" mt={5} mb={5}>
									Customs Manual trackers
								</Typography>
							</Grid>
							<Grid container alignItems="center" direction="column">
								<Grid container alignItems="center" direction="column" px={4}>
									<Button
										onClick={() => setBulkModalOpen(true)}
										sx={{
											mt: 2,
											backgroundColor: '#03C988',
											':hover': {
												backgroundColor: '#057852'
											}
										}}
										variant="contained"
										size="small"
									>
										Update all
									</Button>
									<CustomsTable
										shipments={shipments}
										selectedShipment={selectedShipment}
										handleRowClick={handleRowClick}
										setModalOpen={setModalOpen}
										modalOpen={modalOpen}
										setOpenRow={setOpenRow}
										openRow={openRow}
										bulkModalOpen={bulkModalOpen}
										setBulkModalOpen={setBulkModalOpen}
										bulkLeaveClose={bulkLeaveClose}
										resetModalStyle={resetModalStyle}
										bulkDialogBox={bulkDialogBox}
										customListValue={customListValue}
										setCustomListValue={setCustomListValue}
										customMessage={customMessage}
										setCustomMessage={setCustomMessage}
										setBulkDialogBox={setBulkDialogBox}
										handleBulkUpdates={handleBulkUpdates}
										dialogOpen={dialogOpen}
										setDialogOpen={setDialogOpen}
										listValue={listValue}
										setListValue={setListValue}
										listMessage={listMessage}
										setListMessage={setListMessage}
										enRoute={enRoute}
										enRouteMessage={enRouteMessage}
										arrived={arrived}
										arrivedMessage={arrivedMessage}
										cleared={cleared}
										clearedMessage={clearedMessage}
										scanned={scanned}
										scannedMessage={scannedMessage}
										open={open}
										setOpen={setOpen}
										handleLeaveClose={handleLeaveClose}
										dialogBox={dialogBox}
										setDialogBox={setDialogBox}
										customLocation={customLocation}
										setCustomLocation={setCustomLocation}
									/>
								</Grid>
							</Grid>
						</>
					) : (
						<Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
							<CircularProgress color="inherit" />
						</Backdrop>
					)}
				</>
			)}
			<Snackbar
				open={openSnackBar}
				autoHideDuration={2000}
				onClose={handleAlertClose}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			>
				<Alert onClose={handleAlertClose} severity={snackAlert.type} sx={{ width: '100%' }}>
					{snackAlert.message}
				</Alert>
			</Snackbar>
		</>
	);
}
