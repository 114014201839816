import {
	CircularProgress,
	MenuItem,
	TextField,
	Typography
} from '@mui/material';
import {
	createElementId,
	SHIPMENT_TABLE,
	DROPDOWN,
	SELECT,
	TRACKING_STATUS,
	MENU_ITEM
} from '../../constants/id';
import React, { useState } from 'react';
import { TrackingStatus } from '../../../src/types/tracker';

export default function EventStatusDropwdown(props: any) {
	const { shipment, TrackingStatus, axiosInstance, setSnackAlert, setOpenSnackBar } = props;
	const adminUser = JSON.parse(sessionStorage.getItem('user') || '{}');
	const [eventStatus, setEventStatus] = useState(shipment.event_status);
	const statusText = !eventStatus
		? 'Incomplete'
		: eventStatus === TrackingStatus.Delivered
		? 'Delivered'
		: eventStatus === TrackingStatus.Cancelled
		? 'Cancelled'
		: eventStatus === TrackingStatus.PendingCancel
		? 'Pending Cancel'
		: eventStatus === TrackingStatus.Failure
		? 'Exception'
		: eventStatus === TrackingStatus.ReturnToSender
		? 'Returned'
		: eventStatus === TrackingStatus.InTransit
		? 'In Transit'
		: eventStatus === TrackingStatus.OutForDelivery
		? 'Out For Delivery'
		: !Object.values(TrackingStatus).includes(eventStatus as TrackingStatus) ||
		  eventStatus === TrackingStatus.PreTransit
		? 'Pre Transit'
		: eventStatus === TrackingStatus.AvailableForPickUp
		? 'Available For Pickup'
		: eventStatus;
	const [allowedStatuses, setAllowedStatuses] = useState(true);
	const isEditible =
		eventStatus === TrackingStatus.PendingCancel ||
		eventStatus === TrackingStatus.InTransit ||
		eventStatus === TrackingStatus.OutForDelivery;
	const [loading, setLoading] = useState(false);
	const statuses = [
		{
			event_status: TrackingStatus.PendingCancel,
			allowed_statuses: allowedStatuses,
			status_text: 'Pending Cancel'
		},
		{
			event_status: TrackingStatus.PreTransit,
			allowed_statuses: !allowedStatuses,
			status_text: 'Pre Transit'
		},
		{
			event_status: TrackingStatus.InTransit,
			allowed_statuses: allowedStatuses,
			status_text: 'In Transit'
		},
		{
			event_status: TrackingStatus.OutForDelivery,
			allowed_statuses: allowedStatuses,
			status_text: 'Out For Delivery'
		},
		{
			event_status: TrackingStatus.Cancelled,
			allowed_statuses: allowedStatuses,
			status_text: 'Cancelled'
		},
		{
			event_status: TrackingStatus.Delivered,
			allowed_statuses: allowedStatuses,
			status_text: 'Delivered'
		},
		{
			event_status: TrackingStatus.ReturnToSender,
			allowed_statuses: !allowedStatuses,
			status_text: 'Returned'
		},
		{
			event_status: TrackingStatus.AvailableForPickUp,
			allowed_statuses: !allowedStatuses,
			status_text: 'Available for Pickup'
		},
		{
			event_status: TrackingStatus.Failure,
			allowed_statuses: !allowedStatuses,
			status_text: 'Exception'
		}
	];

	const handleMarkAsDelivered = async (event: any) => {
		const deliveryStatus = event.target.value;

		if (deliveryStatus === TrackingStatus.Delivered) {
			setEventStatus(deliveryStatus);
			setLoading(true);
		} else if (deliveryStatus === TrackingStatus.OutForDelivery) {
			setEventStatus(deliveryStatus);
			setLoading(true);
		} else if (deliveryStatus === TrackingStatus.Cancelled) {
			setEventStatus(deliveryStatus);
			setLoading(true);
		} else if (deliveryStatus === TrackingStatus.PendingCancel) {
			setEventStatus(deliveryStatus);
			setLoading(true);
		} else if (deliveryStatus === TrackingStatus.InTransit) {
			setEventStatus(deliveryStatus);
			setLoading(true);
		}
		const response = await axiosInstance.post('/shipment/updateShipmentEventStatus', {
			shipment_id: shipment.shipment_id,
			event_status: deliveryStatus
		});
		if (response.status === 200) {
			setSnackAlert({ type: 'success', message: 'Shipment status updated successfully' });
			setOpenSnackBar(true);
			setLoading(false);
		} else {
			setSnackAlert({ type: 'error', message: 'Failed to update shipment status' });
			setOpenSnackBar(true);
			setLoading(false);
		}
	};

	return (
		<div>
			<Typography
				noWrap={true}
				p={0.2}
				sx={{
					backgroundColor: loading ? 'gray' : !eventStatus
						? '#EA2027'
						: eventStatus === TrackingStatus.PreTransit
						? '#0652DD'
						: eventStatus === TrackingStatus.InTransit
						? '#ff9f1a'
						: eventStatus === TrackingStatus.OutForDelivery
						? '#fff200'
						: eventStatus === TrackingStatus.Cancelled
						? 'red'
						: eventStatus === TrackingStatus.PendingCancel
						? 'grey'
						: eventStatus === TrackingStatus.ReturnToSender
						? '#ff9f1a'
						: eventStatus === TrackingStatus.Failure
						? '#ff9f1a'
						: eventStatus === TrackingStatus.Delivered
						? '#4cd137'
						: eventStatus === TrackingStatus.AvailableForPickUp
						? '#37d1ad'
						: '#0652DD',
					borderRadius: '20px',
					textAlign: 'center',
					color: loading ? '#fff' : !eventStatus
						? '#000'
						: eventStatus === TrackingStatus.PreTransit
						? '#fff'
						: eventStatus === TrackingStatus.InTransit
						? '#000'
						: eventStatus === TrackingStatus.Failure
						? '#000'
						: eventStatus === TrackingStatus.Cancelled
						? '#fff'
						: eventStatus === TrackingStatus.PendingCancel
						? '#fff'
						: eventStatus === TrackingStatus.OutForDelivery
						? '#000'
						: eventStatus === TrackingStatus.Delivered
						? '#000'
						: eventStatus === TrackingStatus.AvailableForPickUp
						? '#000'
						: '#fff'
				}}
			>
				{loading ? (
					<CircularProgress color="inherit" size={14} sx={{ mt: '1vh' }} />
				) : isEditible && adminUser.admin_access ? (
					<TextField
						id={createElementId([SHIPMENT_TABLE, TRACKING_STATUS, SELECT, DROPDOWN, 'Event_Status'])}
						defaultValue={eventStatus}
						select
						onChange={handleMarkAsDelivered}
						variant="standard"
						fullWidth
						sx={{
							height: '25px',
							paddingLeft: '15px'
						}}
						InputProps={{
							style: { color: eventStatus === TrackingStatus.PendingCancel ? '#FFFFFF' : '#000000' }
						}}
					>
						{statuses.map(
							(items: any) =>
								items.allowed_statuses && (
									<MenuItem
										id={createElementId([
											SHIPMENT_TABLE,
											TRACKING_STATUS,
											SELECT,
											MENU_ITEM,
											items.event_status
										])}
										value={items.event_status}
										style={{ display: items.event_status === 'pending_cancel' ? 'none' : 'flex' }}
									>
										{items.status_text}
									</MenuItem>
								)
						)}
					</TextField>
				) : (
					statusText
				)}
				{/* {!shipment.event_status
								? 'Incomplete'
								: shipment.event_status === TrackingStatus.Delivered
								? 'Delivered'
								: shipment.event_status === TrackingStatus.Cancelled
								? 'Cancelled'
								: shipment.event_status === TrackingStatus.PendingCancel
								? 'Cancel Pending'
								: shipment.event_status === TrackingStatus.Failure
								? 'Exception'
								: shipment.event_status === TrackingStatus.ReturnToSender
								? 'Returned'
								: shipment.event_status === TrackingStatus.InTransit
								? 'In Transit'
								: shipment.event_status === TrackingStatus.OutForDelivery
								? 'Out For Delivery'
								: !Object.values(TrackingStatus).includes(shipment.event_status as TrackingStatus) ||
								  shipment.event_status === TrackingStatus.PreTransit
								? 'Pre Transit'
								: shipment.event_status === TrackingStatus.AvailableForPickUp
								? 'Available For Pickup'
								: shipment.event_status} */}
			</Typography>
		</div>
	);
}
