import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, FormControl, Grid, IconButton, MenuItem, Paper, Popover, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { pickupDateFedex } from '../../../../util/date';

interface ExpressPickUpFormValues {
	collection: number;
	timeSlot: number;
}

export default function ExpressPickUpNotice(props: any) {
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const { pickUp, setPickUp, timeSlot, setTimeSlot } = props;

	const {
		register,
		setError,
		clearErrors,
		formState: { errors }
	} = useForm<ExpressPickUpFormValues>();

	const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	const handlePickUpSelectOnChange = (event: any) => {
		setPickUp(event.target.value);
	};

	const open = Boolean(anchorEl);

	const { today, tomorrow, monday } = {
		...pickupDateFedex()
	};

	// Watch pickUp and timeSlot changes for validation
	useEffect(() => {
		if (pickUp === 1 && timeSlot === 0) {
			setError('timeSlot', { type: 'required', message: 'Time slot is required when collection is selected.' });
		} else if (pickUp === 0 || pickUp === 7) {
			setTimeSlot(0);
			clearErrors('timeSlot');
		} else {
			clearErrors('timeSlot');
		}
	}, [pickUp, timeSlot]);

	return (
		<Paper elevation={4} square={false}>
			<Box p={2}>
				<Grid container spacing={2}>
					<Grid item md={12}>
						<Box>
							<IconButton
								aria-owns={open ? 'mouse-over-popover' : undefined}
								aria-haspopup="true"
								onMouseEnter={handlePopoverOpen}
								onMouseLeave={handlePopoverClose}
								sx={{ padding: '0px' }}
							>
								<Typography variant="h5" mr={2} sx={{ fontWeight: 'bold', color: 'black' }}>
									Parcel Collection Date
								</Typography>
								<InfoOutlinedIcon />
							</IconButton>
							{collectionPopOver(open, anchorEl, handlePopoverClose)}
						</Box>
					</Grid>

					<Grid item md={12}>
						<Grid container spacing={2}>
							<Grid item md={6}>
								<FormControl variant={'standard'} fullWidth>
									<TextField
										select
										value={pickUp}
										label="Parcel Collection"
										{...register('collection')}
										onChange={handlePickUpSelectOnChange}
										fullWidth
									>
										<MenuItem value={1}>Yes</MenuItem>
										<MenuItem value={7}>No: I am sending my parcel to TUNL for pick up</MenuItem>
										<MenuItem value={0}>
											No: I already have a pick up scheduled at my location
										</MenuItem>
									</TextField>
								</FormControl>
							</Grid>
							<Grid item md={6}>
								<FormControl variant={'standard'} fullWidth>
									<TextField
										select
										value={timeSlot}
										fullWidth
										disabled={pickUp === 0 || pickUp === 7 ? true : false}
										{...register('timeSlot')}
										onChange={event => setTimeSlot(event.target.value)}
										error={!!errors.timeSlot}
										helperText={errors.timeSlot?.message}
									>
										<MenuItem disabled value={0}>
											<em>Please Select Time Slot</em>
										</MenuItem>
										{today?.morning && <MenuItem value={1}>Today (Morning)</MenuItem>}
										{today?.afternoon && <MenuItem value={2}>Today (Afternoon)</MenuItem>}
										{tomorrow?.morning && <MenuItem value={3}>Tomorrow (Morning)</MenuItem>}
										{tomorrow?.afternoon && <MenuItem value={4}>Tomorrow (Afternoon)</MenuItem>}
										{monday?.morning && <MenuItem value={5}>Monday (Morning)</MenuItem>}
										{monday?.afternoon && <MenuItem value={6}>Monday (Afternoon)</MenuItem>}
									</TextField>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Box>
		</Paper>
	);
}
function collectionPopOver(open: boolean, anchorEl: HTMLElement | null, handlePopoverClose: () => void) {
	return (
		<Popover
			id="mouse-over-popover"
			sx={{ pointerEvents: 'none' }}
			open={open}
			anchorEl={anchorEl}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
			transformOrigin={{ vertical: 'top', horizontal: 'left' }}
			onClose={handlePopoverClose}
			disableRestoreFocus
		>
			<Paper>
				<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 2 }}>
					<Typography textAlign="start">Morning Pickups 9am - 12pm</Typography>
					<Typography textAlign="start">Afternoon Pickups 12pm - 5pm</Typography>
				</Box>
			</Paper>
		</Popover>
	);
}
