import { Button, Grid, Typography } from '@mui/material';

function NotFound() {
	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				height: '80vh',
				backgroundImage: `url(${'../../img/astronaut.png'})`,
				backgroundRepeat: 'no-repeat',
				backgroundPosition: 'right bottom',
				backgroundSize: '40vh'
			}}
		>
			<Grid container direction="column" alignItems="center">
				<Grid
					item
					sx={{
						paddingBottom: '20px'
					}}
				>
					<Typography variant="h3">Oops! 404 page not found ...</Typography>
				</Grid>
				<Grid item>
					<Button variant="contained" color="primary" href="/">
						home
					</Button>
				</Grid>
			</Grid>
		</div>
	);
}

export default NotFound;
