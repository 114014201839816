const TEXT = {
	DASHBOARD: 'Dashboard',
	BULK_SHIPMENTS: 'Bulk Shipments',
	CUSTOMS_DASHBOARD: 'Customs Dashboard',
	VIEW_SHIPMENTS: 'View Shipments',
	CREATE_NEW_BOOKING: 'Create New Booking',
	CREATE_SHIPMENT: 'Create Shipment',
	PRODUCT_LIBRARY: 'Product Library',
	MERCHANT_PROFILE: 'Merchant Profile',
	MANAGE_USERS: 'Manage Users',
	SALES_CHANNELS: 'Sales Channels',
	TRACKER: 'Tracker',
	TOOLS: 'Tools',
	LOGOUT: 'Logout',
	SHIPPING_COST: 'Shipping Cost',
	SHIPPING_PROTECTION: 'Shipping Protection',
	DFT: 'Duties, Fees and Taxes',
	SIGNATURE_REQUIRED: 'Signature Required',
	CSV_BASE_HEADERS: [
		'Order Number',
		'Address To Name',
		'Address To Company',
		'Address To Street 1',
		'Address To Street 2',
		'Address To City Locality',
		'Address To State Province',
		'Address To Zip Postal',
		'Address To Country',
		'Address To Phone',
		'Address To Email',
		'Parcel Length (cm)',
		'Parcel Width (cm)',
		'Parcel Height (cm)',
		'Parcel Weight (kg)',
		'Service',
		'Shipping Type',
		'Insurance',
		'Invoice',
		'Reference',
		'Content Type',
		'Signature Required'
	],
	CSV_DYNAMIC_HEADERS: [
		'(Item Description)',
		'(HS Code)',
		'(Quantity)',
		'(Total Weight)',
		'(Total Value)',
		'(Currency)'
	]
};

export const { BULK_SHIPMENTS, SHIPPING_COST, SHIPPING_PROTECTION, DFT, SIGNATURE_REQUIRED } = TEXT;
export default TEXT;
