import { Theme } from '@mui/material';

export default function getAmplifyTheme(theme: Theme) {
	return {
		name: "TUNL Theme",
		tokens: {
			components: {
				authenticator: {
					form: {
						padding: { value: "2rem 2rem 0.8rem 2rem" }
					}
				},
				button: {
					primary: {
						_hover: {
							backgroundColor: { value: theme.palette.primary.dark }
						},
						_focus: {
							backgroundColor: { value: theme.palette.primary.dark }
						},
						_active: {
							backgroundColor: { value: theme.palette.primary.dark }
						},
						backgroundColor: { value: theme.palette.primary.main }
					},
					paddingBlockStart: { value: "8px" },
					paddingBlockEnd: { value: "8px" },
					link: {
						_active: { backgroundColor: { value: "none" }, color: { value: theme.palette.primary.main }, borderColor: { value: "#fff" } },
						_focus: { backgroundColor: { value: "none" }, color: { value: theme.palette.primary.main }, borderColor: { value: "#fff" } },
						_hover: { backgroundColor: { value: "none" }, color: { value: theme.palette.primary.main } },
						color: { value: theme.palette.primary.main },
						borderColor: { value: "#fff" },
						borderWidth: { value: "0px" }
					},
					fontWeight: { value: "normal" }
				},
				fieldcontrol: {
					_focus: {
						borderColor: { value: theme.palette.primary.main },
						boxShadow: { value: `0px 0px 0px 1px ${theme.palette.primary.main}` }
					},
					_error: {
						borderColor: { value: theme.palette.error.main },
						_focus: {
							boxShadow: { value: "none" }
						}
					},
					borderColor: { value: "#00000033" },
					lineHeight: { value: "1.4375em" },
					borderRadius: { value: `${theme.shape.borderRadius}px` },
					paddingBlockStart: { value: "12px" },
					paddingBlockEnd: { value: "12px" }
				}
			}
		}
	}
}