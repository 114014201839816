import { Grid, TextField } from '@mui/material'
import AddressAutoComplete from './Shipment/CreateShipmentForm/AddressAutoComplete'
import { useState } from 'react';
import { FieldErrors, UseFormRegister, UseFormSetValue, UseFormTrigger, UseFormWatch } from 'react-hook-form';
import { createElementId, CREATE_MERCHANT, MERCHANT_ADDRESS } from '../../constants/id';

export default function MerchantAddress({ 
	register,
	setValue,
	trigger,
	errors,
	watch
	}: { 
		register: UseFormRegister<any>,
		setValue: UseFormSetValue<any>,
		trigger: UseFormTrigger<any>,
		errors: FieldErrors<any>,
		watch: UseFormWatch<any>
	}) {
	const [merchant_address, setMerchantAddressDetails] = useState({
		merchant_street_1: '',
		merchant_zip_postal: '',
		merchant_city_locality: '',
		merchant_state_province: '',
		merchant_country: ''
	});

	const handleAutAddressChanged = (address: any) => {
		setValue('merchant_street_1', address.merchant_street_1);
		setValue('merchant_zip_postal', address.merchant_zip_postal);
		setValue('merchant_city_locality', address.merchant_city_locality);
		setValue('merchant_state_province', address.merchant_state_province);
		setValue('merchant_country', address.merchant_country);

		setMerchantAddressDetails(address);
	}

	const handleAddressChange = (e: any) => {
		const { name, value } = e.target;
		setValue(name, value);
		trigger(name);

		//if this field isnt country field, default to ZA
		if(name !== 'merchant_country') {
			setValue('merchant_country', 'ZA');
		}
	};

	const isToAddressFilled = () => {
		return (
			merchant_address.merchant_street_1?.trim() !== '' ||
			merchant_address.merchant_city_locality?.trim() !== '' ||
			merchant_address.merchant_state_province?.trim() !== '' ||
			merchant_address.merchant_zip_postal?.trim() !== '' ||
			merchant_address.merchant_country?.trim() !== ''
		);
	};

	const isAddressFormFilled = isToAddressFilled();

	return (
		<Grid 
			direction='column'
			container 
			spacing={2}
		>
			<Grid item>
				<AddressAutoComplete
					addressId={createElementId([CREATE_MERCHANT, MERCHANT_ADDRESS, 'merchant_address'])}
					required={ !isAddressFormFilled }
					setMerchantAddressDetails={handleAutAddressChanged}
				/>
			</Grid>
			{isAddressFormFilled && (
				<Grid item>
					<Grid 
						container 
						spacing={2}
					>
						<Grid 
							item 
							xs={12}
							lg={6}
						>
							<TextField
								id={createElementId([CREATE_MERCHANT, MERCHANT_ADDRESS, 'merchant_street_1'])}
								required
								label='Address Line 1'
								{...register('merchant_street_1', { required: 'Required' })}
								helperText={
									errors?.merchant_street_1
										? (errors.merchant_street_1.message as string)
										: null
								}
								fullWidth
								value={watch('merchant_street_1') || ''}
								onChange={handleAddressChange}
								inputProps={{ 'data-hj-allow': true }}
							/>
						</Grid>
						<Grid 
							item 
							xs={12}
							lg={6}
						>
							<TextField
								id={createElementId([CREATE_MERCHANT, MERCHANT_ADDRESS, 'merchant_street_2'])}
								label='Address Line 2'
								fullWidth
								{...register('merchant_street_2', {})}
								helperText='Apartment, building, floor(optional)'
								value={watch('merchant_street_2') || ''}
								onChange={handleAddressChange}
								inputProps={{ 'data-hj-allow': true }}
							/>
						</Grid>					
						<Grid 
							item 
							xs={12}
							lg={6}
						>
							<TextField
								id={createElementId([CREATE_MERCHANT, MERCHANT_ADDRESS, 'merchant_city_locality'])}
								required
								label='City'
								fullWidth
								{...register('merchant_city_locality', {
									required: 'Required'
								})}
								error={!!errors?.merchant_city_locality}
								helperText={
									errors?.merchant_city_locality
										? (errors.merchant_city_locality.message as string)
										: null
								}
								value={watch('merchant_city_locality') || ''}
								onChange={handleAddressChange}
								inputProps={{ 'data-hj-allow': true }}
							/>
						</Grid>
						<Grid
							item 
							xs={12}
							lg={6}
						>
							<TextField
								id={createElementId([CREATE_MERCHANT, MERCHANT_ADDRESS, 'merchant_state_province'])}
								required
								label='Province'
								fullWidth
								{...register('merchant_state_province', {
									required: 'Required',
									pattern: {
										value: /^[a-z][A-Z]{1}$/i,
										message: 'Invalid input. Province must be a 2 letter code'
									}
								})}
								error={!!errors?.merchant_state_province}
								helperText={
									errors?.merchant_state_province
										? (errors.merchant_state_province.message as string)
										: null
								}
								value={watch('merchant_state_province') || ''}
								onChange={handleAddressChange}
								inputProps={{ 'data-hj-allow': true }}
							/>
						</Grid>						
						<Grid
							item 
							xs={12}
							lg={6}
						>
							<TextField
								id={createElementId([CREATE_MERCHANT, MERCHANT_ADDRESS, 'merchant_zip_postal'])}
								required
								label='Postal Code'
								fullWidth
								{...register('merchant_zip_postal', {
									required: 'Required'
								})}
								error={!!errors?.merchant_zip_postal}
								helperText={
									errors?.merchant_zip_postal
										? (errors.merchant_zip_postal.message as string)
										: null
								}
								value={watch('merchant_zip_postal') || ''}
								onChange={handleAddressChange}
								inputProps={{ 'data-hj-allow': true }}
							/>
						</Grid>
						<Grid
							item 
							xs={12}
							lg={6}
						>
							<TextField
								id={createElementId([CREATE_MERCHANT, MERCHANT_ADDRESS, 'merchant_country'])}
								required
								label='Country'
								fullWidth
								{...register('merchant_country', {
									required: 'Required',
									pattern: {
										value: /^[a-z][A-Z]{1}$/i,
										message: 'Invalid input. Country must be a 2 letter code'
									}
								})}
								error={!!errors?.merchant_country}
								helperText={
									errors?.merchant_country ? (errors.merchant_country.message as string) : null
								}
								value={watch('merchant_country') || ''}
								onChange={handleAddressChange}
								inputProps={{ 'data-hj-allow': true }}
							/>
						</Grid>
					</Grid>
				</Grid>
			)}
		</Grid>
	)
}