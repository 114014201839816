import { Alert, AlertTitle } from '@material-ui/core';
import { AlertColor, Grid, Typography } from '@mui/material';
import LearnMore from './LearnMore';
import CircularWithValueLabel from '../Merchant/Product/ConfidenceScore';

interface TNAlertsProps {
	severity: AlertColor;
	title: string;
	message: string;
	link?: string;
	confidence?: number;
}

export default function TNAlerts({ severity, title, message, link, confidence }: TNAlertsProps) {
	return (
		<div>
			<Alert severity={severity}>
				<Grid
					container
					direction={'row'}
					alignItems={'space-around'}
					justifyContent={'space-between'}
					xs={12}
					sm={12}
					md={12}
					lg={12}
					xl={12}
				>
					<Grid
						item
						xs={12}
						sm={12}
						md={confidence ? 7 : 12}
						lg={confidence ? 7 : 12}
						xl={confidence ? 7 : 12}
					>
						<AlertTitle>
							<Typography variant={'h6'} sx={{ fontWeight: 'bold' }}>
								{title}
							</Typography>
						</AlertTitle>
						{confidence ? (
							<Typography
								sx={{
									wordWrap: 'break-word',
									whiteSpace: 'pre-line',
									overflow: 'auto',
									maxHeight: '25vh'
								}}
							>
								{message || 'No message provided'}
							</Typography>
						) : (
							<Typography sx={{ wordWrap: 'break-word', whiteSpace: 'pre-line' }}>
								{message || 'No message provided'}
							</Typography>
						)}
					</Grid>
					{confidence && (
						<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
							<Grid container direction={'column'} alignItems={'center'} justifyContent={'center'}>
								<Typography variant={'h6'} sx={{ fontWeight: 'bold', marginBottom: 1 }}>
									Confidence Score
								</Typography>
								<CircularWithValueLabel value={confidence} />
							</Grid>
						</Grid>
					)}
					{link && (
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
							<LearnMore href={link} />
						</Grid>
					)}
				</Grid>
			</Alert>
		</div>
	);
}
