import React, { useEffect, useState } from 'react';
import { TextField, Autocomplete, Box, InputAdornment } from '@mui/material';
import { Storefront } from '@mui/icons-material';

import { createElementId, DROPDOWN, FORM_CONTROL, MERCHANT_PROFILE, SELECT, TEXTFIELD } from '../../constants/id';
import { MerchantRecord } from '../../types/merchant';
import { UserMerchantRecord } from '../../types/user';
import { SxProps } from '@mui/system';
import { useMerchant } from '../../context/MerchantContext';

interface MerchantSelectProps {
	sx?: SxProps;
}
interface MerchantSearchType {
	id: string;
	label: string;
}

const MerchantSelect: React.FC<MerchantSelectProps> = ({ sx }) => {
	const { merchantProfile, handleMerchantSelect, availableMerchants } = useMerchant();
	const [currentMerchant, setCurrentMerchant] = useState<MerchantRecord>(merchantProfile);
	const [selectedMerchant, setSelectedMerchant] = useState({
		id: currentMerchant.merchant_id,
		label: currentMerchant.merchant_name
	});

	useEffect(() => {
		setCurrentMerchant(merchantProfile);
	}, [merchantProfile]);

	const merchantOptions: readonly MerchantSearchType[] = availableMerchants.map(merchant => ({
		id: merchant.merchant_id,
		label: merchant.merchant_name
	}));

	const updateSelectedMerchant = async (selectedMerchant: UserMerchantRecord) => {
		if (currentMerchant.partitionKey === selectedMerchant.merchant_id) {
			return;
		}

		try {
			await handleMerchantSelect(selectedMerchant);
		} catch (error) {
			console.log(error);
		}
	};

	const handleMerchantChange = (event: any, merchantOption: MerchantSearchType | null) => {
		if (merchantOption) {
			setSelectedMerchant(merchantOption);
			const selectedMerchant = availableMerchants.find(
				merchant => merchant.merchant_name === merchantOption.label
			) as UserMerchantRecord;
			updateSelectedMerchant(selectedMerchant);
		}
	};

	return (
		<Autocomplete
			id={createElementId([MERCHANT_PROFILE, FORM_CONTROL, TEXTFIELD, DROPDOWN, SELECT, 'merchant_select'])}
			value={selectedMerchant}
			onChange={(event: any, newValue: MerchantSearchType | null) => {
				handleMerchantChange(event, newValue);
			}}
			options={merchantOptions}
			getOptionLabel={option => option.label}
			sx={sx}
			disableClearable
			renderInput={params => (
				<TextField
					variant="standard"
					{...params}
					InputProps={{
						...params.InputProps,
						disableUnderline: true,
						startAdornment: (
							<InputAdornment position="start">
								<Storefront />
							</InputAdornment>
						)
					}}
				/>
			)}
			renderOption={(props, option) => (
				<Box component="li" {...props} key={option.id} sx={{zIndex: theme => theme.zIndex.drawer + 1}}>
					{option.label}
				</Box>
			)}
		/>
	);
};

export default MerchantSelect;
