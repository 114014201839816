import CreateMerchant from '../Merchant/CreateMerchant';
import MerchantDashboard from '../Merchant/MerchantDashboard';
import { useMerchant } from '../../context/MerchantContext';
import { Navigate } from 'react-router-dom';

function Home(props: any) {
	const { merchantProfile } = useMerchant();
	return <MerchantDashboard />;
}

export default Home;
