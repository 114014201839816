import { CircularProgressProps, Box, CircularProgress, Typography, Grid } from '@mui/material';
import React from 'react';
function CircularProgressWithLabel(props: CircularProgressProps & { value: number }) {
	return (
		<Grid
			container
			direction={'column'}
			xs={12}
			sm={12}
			md={12}
			lg={12}
			xl={12}
			alignItems={'center'}
			justifyContent={'center'}
		>
			<Grid item xs={12} sm={12} md={12} lg={12} xl={12} position="relative" display="inline-flex">
				<CircularProgress
					size="5rem"
					variant="determinate"
					{...props}
					color={
						props.value <= 35
							? 'inherit'
							: props.value < 50
							? 'error'
							: props.value < 71
							? 'warning'
							: props.value < 90
							? 'info'
							: 'success'
					}
				/>
				<Box
					top={0}
					left={0}
					bottom={0}
					right={0}
					position="absolute"
					display="flex"
					alignItems="center"
					justifyContent="center"
				>
					<Typography variant="h6" component="div" color="textSecondary">
						{`${Math.round(props.value)}%`}
					</Typography>
				</Box>
			</Grid>
		</Grid>
	);
}
export default function CircularWithValueLabel(value: any) {
	const [progress, setProgress] = React.useState(0);
	//useEffect to calculate persentage of progress using the value passed in
	React.useEffect(() => {
		const timer = setInterval(() => {
			const persentage = value.value * 100;
			setProgress(prevProgress => (prevProgress >= persentage ? persentage : prevProgress + 0.2));
		}, 5);
		return () => {
			clearInterval(timer);
		};
	}, [value]);
	return (
		<div>
			<CircularProgressWithLabel value={progress} />
		</div>
	);
}
